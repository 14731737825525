import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import styled from "styled-components"
import { Colors } from "constants/colors"

const MentionsLegales = ({ className, children, handleAccept = null, ...props }) => {
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)
	const _handleAccept = () => {
		if (typeof handleAccept === "function") handleAccept()
		handleClose()
	}
	return (
		<div className={className}>
			<div onClick={handleShow}>{children}</div>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header
					closeButton
					style={{ border: "none", alignItems: "flex-start" }}
				></Modal.Header>
				<Modal.Header style={{ border: "none", alignItems: "flex-start" }}>
					<Modal.Title
						style={{
							color: Colors.dark,
							fontSize: "35px",
							fontWeight: "700",
							lineHeight: "37px",
						}}
					>
						Mentions légales
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<b>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</b>
					<p>
						sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
						veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
						consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
						eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
						qui officia deserunt mollit anim id est laborum.
					</p>
					<b>Lorem ipsum dolor sit amet, consectetur adipiscing</b>
					<p>
						elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
						minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
						consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
						eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
						qui officia deserunt mollit anim id est laborum.
					</p>
				</Modal.Body>
				<Modal.Footer style={{ border: "none" }}>
					<button
						onClick={_handleAccept}
						style={{
							backgroundColor: Colors.lightGreen,
							color: Colors.white,
							border: "none",
							borderRadius: "25px",
							padding: "16px",
							fontWeight: "bold",
							fontSize: "16px",
							width: "100%",
							textAlign: "left",
						}}
					>
						Accepter
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	)
}

export default styled(MentionsLegales)`
	display: inline;
`
