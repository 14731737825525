import React from "react"
import Accueil from "assets/home-contour.svg"
import AccueilGreen from "assets/home-green.svg"

const AccueilIcon = ({ active, ...porps }) => {
	if (active) return <img src={AccueilGreen} alt="" />
	return <img src={Accueil} alt="" />
}

export default AccueilIcon
