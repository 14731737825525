import api from "./api"

/**
 * Objet contenant des méthodes pouvant être utilisées pour gérer les fichiers au sein de l'application.
 */
const FileUtils = {
	uploadFile: async (url, file) => {
		const data = new FormData()
		data.append("name", "file")
		data.append("file", file)
		const response = await api.post(url, data)
		return response
	},

	createDownloadLink: (url, filename, options) => {
		let a = document.createElement("a")
		a.href = url
		a.download = filename
		if (options?.blank) a.target = "_blank"
		document.body.appendChild(a)
		a.click()
		setTimeout(() => {
			document.body.removeChild(a)
			window.URL.revokeObjectURL(url)
		}, 0)
	},
}

export default FileUtils
