import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Colors } from "constants/colors"
import { GALERIE_MEDIA_ENDPOINT } from "constants/endpoints"
import { breakPoint } from "constants/front"
import api from "services/api"
import DesktopLayout from "components/common/DesktopLayout"
import Loader from "components/common/Loader"
import GalerieMediaItem from "./GalerieMediaItem"
import { GalerieContainer } from "styles/GalerieMedia.style"
import Close from "assets/close-grey.svg"

const ContenuGalerie = ({ className, ...props }) => {
	const [galerieMedias, setGalerieMedias] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		async function fetchData() {
			await api
				.get(GALERIE_MEDIA_ENDPOINT + "?pagination=false&order[createdAt]=desc")
				.then(data => {
					setGalerieMedias(data["hydra:member"])
				})
			setIsLoading(false)
		}
		fetchData()
	}, [])

	if (isLoading) return <Loader />

	return (
		<GalerieContainer>
			{galerieMedias && galerieMedias.map((e, key) => <GalerieMediaItem gm={e} key={key} />)}
		</GalerieContainer>
	)
}

const Galerie = ({ className, ...props }) => {
	const [width, setWidth] = useState(window.innerWidth)

	let navigate = useNavigate()

	useEffect(() => {
		const handleResizeWindow = () => setWidth(window.innerWidth)
		window.addEventListener("resize", handleResizeWindow)
		return () => {
			window.removeEventListener("resize", handleResizeWindow)
		}
	}, [])

	if (width > breakPoint)
		return (
			<DesktopLayout>
				<div
					className={className}
					style={{
						minHeight: "calc(100vh - 120px)",
						maxHeight: "calc(100vh - 120px)",
						overflowX: "hidden",
					}}
				>
					<Col md="0" lg="3" xl="4"></Col>
					<Col>
						<div onClick={() => navigate(-1)} className="layout-row-close">
							<img src={Close} alt=" " style={{ height: "20px", width: "20px" }} />
						</div>
						<Row className="galerie-title">Galerie photos</Row>
						<ContenuGalerie />
					</Col>
					<Col md="0" lg="3" xl="4"></Col>
				</div>
			</DesktopLayout>
		)

	return (
		<div className={className}>
			<Col md="0" lg="3" xl="4"></Col>
			<Col className="layout-col" xs="12" lg="6" xl="4">
				<div onClick={() => navigate(-1)} className="layout-row-close">
					<img src={Close} alt=" " style={{ height: "20px", width: "20px" }} />
				</div>
				<Row className="galerie-title">Galerie photos</Row>
				<ContenuGalerie />
			</Col>
			<Col md="0" lg="3" xl="4" className="right-container"></Col>
		</div>
	)
}

export default styled(Galerie)`
	height: 100vh;
	background-color: ${Colors.white};
	display: flex;

	.row {
		margin: 0;
		padding: 0;
	}
	.col {
		margin: 0;
		padding: 0;
	}

	.layout-col {
		margin: 0;
		padding: 0;
		flex-direction: column;
		display: flex;
		background-color: ${Colors.white};
	}

	.layout-row-close {
		padding: 20px;
		display: flex;
		justify-content: flex-end;
		:hover {
			cursor: pointer;
		}
	}

	.galerie-title {
		color: ${Colors.darkGrey};
		font-size: 35px;
		font-weight: 700;
		line-height: 37px;
		margin: 30px 25px 30px;
	}

	@media only screen and (min-width: ${breakPoint + 1}px) {
		.right-container {
			border-left: 1px solid ${Colors.lightBlue};
		}
	}
`
