import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Form, Row } from "react-bootstrap"
import Routes from "router"
import { RESET } from "constants/endpoints"
import api from "services/api"
import UserLayout from "components/common/UserLayout"
import { RegisterButton, RegisterContainer, RegisterFormContainer } from "styles/Register.style"

const ResetPassword = () => {
	const [email, setEmail] = useState("")

	let navigate = useNavigate()

	const submitReset = async event => {
		event.preventDefault()
		let body = {
			email: email,
		}
		api.anon(RESET, body).then(
			response => {
				window.alert("Vous avez reçu le lien de réinitialisation de mot de passe")
				navigate(Routes.login)
			},
			error => {
				window.alert("Réinitialisation impossible")
				navigate(Routes.login)
			}
		)
	}

	const validateForm = () => {
		return email && email.length > 7
	}

	return (
		<UserLayout title="Mot de passe oublié ?">
			<RegisterContainer>
				<Row
					className="h-100"
					style={{ justifyContent: "center", alignItems: "center", margin: "0" }}
				>
					<RegisterFormContainer onSubmit={submitReset} className="login-form">
						<Form.Group
							size="md"
							controlId="email"
							className="form-group"
							style={{ width: "100%", margin: "auto" }}
						>
							<Form.Control
								autoFocus
								type="email"
								value={email}
								onChange={e => setEmail(e.target.value)}
								placeholder="Votre adresse e-mail"
							/>
						</Form.Group>
						<div className="form-group" style={{ marginBottom: "0" }}>
							<RegisterButton type="submit" disabled={!validateForm()}>
								Valider
							</RegisterButton>
						</div>
					</RegisterFormContainer>
				</Row>
			</RegisterContainer>
		</UserLayout>
	)
}

export default ResetPassword
