import React, { useContext } from "react"
import { NavLink, useLocation } from "react-router-dom"
import AuthenticationContext from "contexts/AuthenticationContext"
import Router from "router"
import AuthService from "services/auth"
import AccueilIcon from "./Icons/AccueilIcon"
import PartagerIcon from "./Icons/PartagerIcon"
import ExplorerIcon from "./Icons/ExplorerIcon"
import JardinerIcon from "./Icons/JardinerIcon"
import { NavigationResponsiveContainer } from "styles/Navigation.style"
import Account from "assets/user-contour.svg"
import Contact from "assets/contact-contour.svg"
import { CONTACT } from "constants/environnement"

const DesktopMenu = () => {
	const { setUser } = useContext(AuthenticationContext)
	let location = useLocation()

	const logOut = () => {
		AuthService.logout()
		setUser(undefined)
	}

	return (
		<NavigationResponsiveContainer className="sidebar navbar-expand">
			<NavLink to={Router.hub.hub} className="nav-link">
				<AccueilIcon
					active={location.pathname === Router.hub.hub || location.pathname === Router.hub.partager}
				/>
				{/* Accueil */}
			</NavLink>
			<NavLink to={Router.partager} className="nav-link">
				<PartagerIcon active={location.pathname === Router.partager} />
				{/* Partager */}
			</NavLink>
			<NavLink to={Router.explorer.explorer} className="nav-link">
				<ExplorerIcon active={location.pathname === Router.explorer.explorer} />
				{/* Explorer */}
			</NavLink>
			<NavLink to={Router.hub.jardiner} className="nav-link">
				<JardinerIcon active={location.pathname === Router.hub.jardiner} />
				{/* Jardiner */}
			</NavLink>
			<NavLink to={Router.account.account} className="nav-link" style={{ marginTop: "auto" }}>
				<img src={Account} alt="" />
				{/* Jardiner */}
			</NavLink>
			<a href={`mailto:${CONTACT}`} className="nav-link">
				<img src={Contact} alt="" />
				{/* Contactez-nous */}
			</a>
		</NavigationResponsiveContainer>
	)
}

export default DesktopMenu
