import React, { useEffect, useState } from "react"
import api from "services/api"
import { GalerieMediaItemContainer } from "styles/GalerieMedia.style"

const GalerieMediaItem = ({ gm, ...props }) => {
	const [imgSrc, setImgSrc] = useState(null)

	useEffect(() => {
		if (gm && gm.media) {
			api.get(gm.media.contentUrl).then(data => {
				const imageObjectURL = URL.createObjectURL(data)
				setImgSrc(imageObjectURL)
			})
		}
	}, [gm])

	return (
		<GalerieMediaItemContainer>
			<img
				src={imgSrc}
				alt=""
				style={{
					maxWidth: "100%",
				}}
			/>
		</GalerieMediaItemContainer>
	)
}

export default GalerieMediaItem
