import React from "react"
import { Puff } from "react-loader-spinner"
import { MutatingDots } from "react-loader-spinner"
import { Rings } from "react-loader-spinner"
import { Hearts } from "react-loader-spinner"
import styled from "styled-components"
import { Colors } from "constants/colors"
import Arrosage from "assets/arrosage.svg"

const Loader = ({ className, mini = true, backgroundColor, color, style, ...props }) => {
	return (
		<div className={className} style={style}>
			{/* <Puff color={color} /> */}
			{/* <Hearts color={color} /> */}
			{/* <Rings color={color} /> */}
			<img src={Arrosage} alt="" className="spinner" />
			{/* <MutatingDots color={color} secondaryColor={Colors.lightBlueGreen} /> */}
		</div>
	)
}

export default styled(Loader)`
	height: ${props => (props.mini ? "80px" : "100vh")};
	background-color: ${props => (props.backgroundColor ? props.backgroundColor : Colors.white)};
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding-top: ${props => (props.mini ? "0" : "50px")};

	// Custom loader
	.spinner {
		animation: spin infinite 2s linear;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg) scale(0.7);
			// opacity: 0.5;
		}
		50% {
			transform: rotate(180deg) scale(1);
			opacity: 1;
		}
		100% {
			transform: rotate(360deg) scale(0.7);
			// opacity: 0.5;
		}
	}
`
