import React from "react"
import Explorer from "assets/explorer-contour.svg"
import ExplorerGreen from "assets/explorer-green.svg"

const ExplorerIcon = ({ active, ...porps }) => {
	if (active) return <img src={ExplorerGreen} alt="" />
	return <img src={Explorer} alt="" />
}

export default ExplorerIcon
