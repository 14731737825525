import React, { useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import styled from "styled-components"
import { Colors } from "constants/colors"
import { EVENEMENT_ENDPOINT } from "constants/endpoints"
import { breakPoint } from "constants/front"
import api from "services/api"
import EventItem from "components/Hub/EventItem"
import DesktopMenu from "components/Navigation/DesktopMenu"
import NavigationBar from "components/Navigation/NavigationBar"
import DesktopLayout from "components/common/DesktopLayout"

const ContenuPartager = ({ style, ...props }) => {
	const [events, setEvents] = useState([])

	useEffect(() => {
		api.get(EVENEMENT_ENDPOINT + "?pagination=false&order[createdAt]=desc").then(data => {
			console.log(data)
			setEvents(data["hydra:member"])
		})
	}, [])

	return (
		<div className="partager-container" style={style}>
			<div className="partager-title">Partager</div>
			{events && events.map((e, key) => <EventItem event={e} key={key} />)}
		</div>
	)
}

const Partager = ({ className, ...props }) => {
	const [width, setWidth] = useState(window.innerWidth)

	useEffect(() => {
		const handleResizeWindow = () => setWidth(window.innerWidth)
		window.addEventListener("resize", handleResizeWindow)
		return () => {
			window.removeEventListener("resize", handleResizeWindow)
		}
	}, [])

	if (width > breakPoint)
		return (
			<DesktopLayout>
				<div
					className={className}
					style={{
						minHeight: "calc(100vh - 120px)",
						maxHeight: "calc(100vh - 120px)",
						overflowX: "hidden",
					}}
				>
					<Col md="0" lg="3" xl="4"></Col>
					<ContenuPartager />
					<Col md="0" lg="3" xl="4"></Col>
				</div>
			</DesktopLayout>
		)

	return (
		<div className={className}>
			<Col md="0" lg="3" xl="4">
				{width > breakPoint && <DesktopMenu />}
			</Col>
			<Col className="layout-col" xs="12" lg="6" xl="4">
				<ContenuPartager />
			</Col>
			<Col md="0" lg="3" xl="4" className="right-container"></Col>
			<NavigationBar withSpray={false} />
		</div>
	)
}

export default styled(Partager)`
	min-height: 100vh;
	background-color: ${Colors.darkBlueGreen};
	display: flex;

	.row {
		margin: 0;
		padding: 0;
	}
	.col {
		margin: 0;
		padding: 0;
	}

	.layout-col {
		margin: 0;
		padding: 0;
		flex-direction: column;
		display: flex;
	}

	.partager-container {
		padding: 50px 20px 60px;
	}

	.partager-title {
		color: ${Colors.white};
		font-family: Lettown Hills;
		font-size: 40px;
		line-height: 48px;
		letter-spacing: -0.31px;
		margin-bottom: 50px;
	}
`
