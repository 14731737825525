import React, { useContext, useEffect, useState } from "react"
import { Row, Form, Col } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import CGU from "./CGU"
import PolitiqueConf from "./PolitiqueConf"
import MentionsLegales from "./MentionsLegales"
import {
	RegisterContainer,
	RegisterButton,
	RegisterFormContainer,
	ClearPicture,
} from "styles/Register.style"
import api from "services/api"
import { USER_ENDPOINT } from "constants/endpoints"
import AuthenticationContext from "contexts/AuthenticationContext"
import Loader from "components/common/Loader"
import { Colors } from "constants/colors"
import Routes from "router"
import AccountLayout from "components/common/AccountLayout"

const ConsentIndex = ({ className, ...props }) => {
	const [editUser, setEditUser] = useState(null)
	// const [acceptNotif, setAcceptNotif] = useState(Notification.permission === "granted")
	const { user } = useContext(AuthenticationContext)

	let navigate = useNavigate()

	useEffect(() => {
		if (user) setEditUser(user)
	}, [user])

	const validateForm = () => {
		return editUser.acceptCGU && editUser.acceptPolitiqueConf && editUser.acceptMentionLegale
	}

	// const askNotificationPermission = () => {
	// 	// function to actually ask the permissions
	// 	function handlePermission(permission) {
	// 		setAcceptNotif(Notification.permission === "granted")
	// 	}

	// 	// Let's check if the browser supports notifications
	// 	if (!("Notification" in window)) {
	// 		console.log("This browser does not support notifications.")
	// 	} else if (checkNotificationPromise()) {
	// 		Notification.requestPermission().then(permission => {
	// 			handlePermission(permission)
	// 		})
	// 	} else {
	// 		// for Safari
	// 		Notification.requestPermission(permission => {
	// 			handlePermission(permission)
	// 		})
	// 	}
	// }

	// const checkNotificationPromise = () => {
	// 	try {
	// 		Notification.requestPermission().then()
	// 	} catch (e) {
	// 		return false
	// 	}

	// 	return true
	// }

	const handleSubmit = async e => {
		e.preventDefault()
		let body = {
			acceptCGU: editUser.acceptCGU,
			acceptPolitiqueConf: editUser.acceptPolitiqueConf,
			acceptMentionLegale: editUser.acceptMentionLegale,
		}
		api.put(USER_ENDPOINT + "/" + editUser.id, body).then(
			response => {
				window.location.reload()
			},
			error => {
				window.alert("Édition impossible")
			}
		)
	}

	if (!user || !editUser)
		return (
			<Loader
				backgroundColor={Colors.darkBlueGreen}
				style={{ paddingTop: "150px", height: "calc(100vh - 120px)" }}
			/>
		)

	return (
		<AccountLayout>
			<div className="account-container">
				<div className="account-title">Consentements</div>
				<RegisterContainer>
					<Row
						className="h-100"
						style={{ justifyContent: "center", alignItems: "center", margin: "0" }}
					>
						<RegisterFormContainer onSubmit={handleSubmit} className="login-form">
							<Form.Group
								size="md"
								controlId="acceptCGU"
								className="form-group"
								style={{ width: "100%", display: "flex" }}
							>
								<div
									className={editUser.acceptCGU ? "checkmark checked" : "checkmark"}
									onClick={() => setEditUser({ ...editUser, acceptCGU: !editUser.acceptCGU })}
								/>

								<Form.Label>
									J’accepte les
									<CGU
										handleAccept={() =>
											setEditUser({
												...editUser,
												acceptCGU: true,
											})
										}
									>
										<span style={{ textDecoration: "underline", cursor: "pointer" }}>
											Conditions Générales d’Utilisation
										</span>
									</CGU>
								</Form.Label>
							</Form.Group>
							<Form.Group
								size="md"
								controlId="acceptPolitiqueConf"
								className="form-group"
								style={{ width: "100%", display: "flex" }}
							>
								<div
									className={editUser.acceptPolitiqueConf ? "checkmark checked" : "checkmark"}
									onClick={() =>
										setEditUser({ ...editUser, acceptPolitiqueConf: !editUser.acceptPolitiqueConf })
									}
								/>
								<Form.Label>
									J’accepte la
									<PolitiqueConf
										handleAccept={() =>
											setEditUser({
												...editUser,
												acceptPolitiqueConf: true,
											})
										}
									>
										<span style={{ textDecoration: "underline", cursor: "pointer" }}>
											politique de confidentialité
										</span>
									</PolitiqueConf>
								</Form.Label>
							</Form.Group>
							<Form.Group
								size="md"
								controlId="acceptMentionLegale"
								className="form-group"
								style={{ width: "100%", display: "flex" }}
							>
								<div
									className={editUser.acceptMentionLegale ? "checkmark checked" : "checkmark"}
									onClick={() =>
										setEditUser({ ...editUser, acceptMentionLegale: !editUser.acceptMentionLegale })
									}
								/>
								<Form.Label>
									J’accepte les
									<MentionsLegales
										handleAccept={() =>
											setEditUser({
												...editUser,
												acceptMentionLegale: true,
											})
										}
									>
										<span style={{ textDecoration: "underline", cursor: "pointer" }}>
											mentions légales
										</span>
									</MentionsLegales>
								</Form.Label>
							</Form.Group>
							{/* <Form.Group
								size="md"
								controlId="acceptNotif"
								className="form-group"
								style={{ width: "100%", margin: "auto", marginTop: "10px", display: "flex" }}
							>
								<div
									className={acceptNotif ? "checkmark checked" : "checkmark"}
									onClick={askNotificationPermission}
								/>
								<Form.Label>J’accepte de recevoir des notifications</Form.Label>
							</Form.Group> */}
							<div className="form-group" style={{ marginBottom: "0" }}>
								<RegisterButton type="submit" disabled={!validateForm()}>
									Valider
								</RegisterButton>
							</div>
							<div className="form-group logout" style={{ marginBottom: "0" }}>
								<a
									onClick={() => navigate(Routes.account.account)}
									// onClick={e => {
									// 	e.preventDefault()
									// 	new Notification("To do list", {
									// 		body: "Mon text",
									// 		icon: "https://apipro.pierre-fabre.com/upload/files/644a32aba7fef633473429.jpg",
									// 	})
									// }}
								>
									<ClearPicture>Retour</ClearPicture>
								</a>
							</div>
						</RegisterFormContainer>
					</Row>
				</RegisterContainer>
			</div>
		</AccountLayout>
	)
}

export default ConsentIndex
