import React, { useState } from "react"
import { Row } from "react-bootstrap"
import { Colors } from "constants/colors"
import UserLayout from "components/common/UserLayout"
import {
	RegisterContainer,
	RegisterButton,
	RegisterFormContainer,
	ClearPicture,
	SkipStep,
} from "styles/Register.style"
import Add from "assets/plus-big.svg"

const RegisterStepPhoto = ({ handlePhoto, form, setForm, ...props }) => {
	const [image, setImage] = useState(null)

	const onImageChange = event => {
		if (event.target.files && event.target.files[0]) {
			setForm({ ...form, media: event.target.files[0] })
			setImage(URL.createObjectURL(event.target.files[0]))
		}
	}

	return (
		<UserLayout title="Ajouter une photo de profil">
			<RegisterContainer>
				<Row
					className="h-100"
					style={{ justifyContent: "center", alignItems: "center", margin: "0" }}
				>
					<RegisterFormContainer onSubmit={handlePhoto} className="login-form">
						{form.media ? (
							<div className="preview-pp">
								<img src={image} alt="" />
								<ClearPicture
									onClick={() => {
										setImage(null)
										setForm({ ...form, media: null })
									}}
									style={{ color: Colors.white, textDecoration: "underline" }}
								>
									Modifier
								</ClearPicture>
							</div>
						) : (
							<div className="preview-pp">
								<input
									onChange={onImageChange}
									type="file"
									name="file"
									id="file"
									placeholder="Photo de profil"
									className="input-media-pp"
								></input>
								<label for="file">
									<img src={Add} alt="" />
								</label>
							</div>
						)}
						<div
							className="form-group"
							style={{ marginTop: "auto", marginBottom: "10", alignSelf: "flex-end" }}
						>
							<SkipStep onClick={handlePhoto}>Passer cette étape</SkipStep>
						</div>
						<div className="form-group" style={{ marginBottom: "0" }}>
							<RegisterButton type="submit" style={{ marginTop: "0" }}>
								Valider
							</RegisterButton>
						</div>
					</RegisterFormContainer>
				</Row>
			</RegisterContainer>
		</UserLayout>
	)
}

export default RegisterStepPhoto
