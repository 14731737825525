import jwt from "jwt-decode"
import { LOGIN } from "constants/endpoints"
import api from "./api"

class AuthService {
	async login(username, password) {
		return api
			.anon(LOGIN, {
				username,
				password,
			})
			.then(response => {
				if (response?.token) {
					// decode token + add access token to user
					let tempToken = jwt(response.token)
					console.log("LOGIN", response)
					tempToken.accessToken = response.token
					tempToken.refreshToken = response.refresh_token
					localStorage.setItem("user", JSON.stringify(tempToken))
				} else {
					console.log(response)
					throw new Error("Impossible de se connecter")
				}
				return response.data
			})
	}

	logout() {
		localStorage.removeItem("user")
	}

	getCurrentUser() {
		return JSON.parse(localStorage.getItem("user"))
	}
}

export default new AuthService()
