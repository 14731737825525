import React, { useContext, useEffect, useState } from "react"
import { Col, Row, Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import AuthenticationContext from "contexts/AuthenticationContext"
import Router from "router"
import { Colors } from "constants/colors"
import { WHO_AM_I } from "constants/endpoints"
import api from "services/api"
import AuthService from "services/auth"
import AnonLayout from "components/common/AnonLayout"
import { LoginContainer, LoginButton, ForgotPass, NoAccount } from "styles/Login.style.js"

const Login = () => {
	// TODO : mdp oublié
	const [message, setMessage] = useState("")
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")

	const { user, setUser } = useContext(AuthenticationContext)

	let navigate = useNavigate()

	useEffect(() => {
		if (user) navigate(Router.hub.hub)
	}, [user, navigate])

	const validateForm = () => {
		return username.length > 0 && password.length > 0
	}

	const handleLogin = async event => {
		event.preventDefault()

		await AuthService.login(username, password).then(
			() => {
				navigate(Router.hub.hub)
			},
			error => {
				const resMessage =
					(error.response && error.response.data && error.response.data.message) ||
					error.message ||
					error.toString()

				setMessage(resMessage)
			}
		)
		let u = AuthService.getCurrentUser()
		if (u)
			api.get(WHO_AM_I).then(data => {
				console.log(data)
				if (data) setUser(data)
			})
	}

	return (
		<AnonLayout>
			<LoginContainer>
				<Row
					className="h-75"
					style={{ justifyContent: "center", alignItems: "center", margin: "0" }}
				>
					<Col xs="12" sm="8" md="6" lg="5" xl="4" xxl="3">
						<Form onSubmit={handleLogin} className="login-form">
							<Form.Group size="md" controlId="email" className="form-group">
								<Form.Control
									autoFocus
									type="email"
									value={username}
									onChange={e => setUsername(e.target.value)}
									placeholder="Votre adresse e-mail"
								/>
							</Form.Group>
							<Form.Group size="md" controlId="password" className="form-group">
								<Form.Control
									type="password"
									value={password}
									onChange={e => setPassword(e.target.value)}
									placeholder="Votre mot de passe"
								/>
							</Form.Group>
							{message && (
								<div className="form-group">
									<div className="alert alert-danger" role="alert">
										{message}
									</div>
								</div>
							)}
							<div className="form-group">
								<LoginButton type="submit" disabled={!validateForm()}>
									Valider
								</LoginButton>
							</div>
						</Form>
					</Col>
				</Row>
				<Row style={{ display: "flex", justifyContent: "flex-end", marginTop: "auto" }}>
					<ForgotPass onClick={() => navigate(Router.account.mdpOublie)}>
						Mot de passe oublié ?
					</ForgotPass>
				</Row>
				<Row
					style={{
						display: "flex",
						alignContent: "center",
						backgroundColor: Colors.white,
						height: "100px",
					}}
				>
					<NoAccount>Pas encore de compte ?</NoAccount>
					<NoAccount
						style={{
							cursor: "pointer",
							textDecoration: "underline",
							fontWeight: "600",
						}}
						onClick={() => navigate(Router.register)}
					>
						Créez votre compte personnel
					</NoAccount>
				</Row>
			</LoginContainer>
		</AnonLayout>
	)
}

export default Login
