import React, { useContext, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import AuthenticationContext from "contexts/AuthenticationContext"
import { Colors } from "constants/colors"
import { EVENEMENT_ENDPOINT, POTAGER_ENDPOINT, USER_ENDPOINT } from "constants/endpoints"
import { breakPoint } from "constants/front"
import api from "services/api"
import DesktopMenu from "components/Navigation/DesktopMenu"
import MobileMenu from "components/Navigation/MobileMenu"
import NavigationBar from "components/Navigation/NavigationBar"
import Potagers from "assets/LesPotagers-Titre.svg"
import Arrosage from "assets/arrosage.svg"
import moment from "moment"

const HubLayout = ({ className, children, withSpray, ...props }) => {
	const [width, setWidth] = useState(window.innerWidth)
	const { user } = useContext(AuthenticationContext)

	useEffect(() => {
		const handleResizeWindow = () => setWidth(window.innerWidth)
		window.addEventListener("resize", handleResizeWindow)
		return () => {
			window.removeEventListener("resize", handleResizeWindow)
		}
	}, [])

	const handleSpray = () => {
		if (user && window.confirm("Avez-vous arrosé ?")) {
			api
				.post(EVENEMENT_ENDPOINT, {
					description:
						user.prenom +
						" " +
						user.nom +
						" a arrosé le " +
						moment().format("DD MMM YYYY [à] HH:mm"),
					date: moment(),
					statut: true,
					shared: false,
					potager: POTAGER_ENDPOINT + "/" + user.potager?.id,
					user: USER_ENDPOINT + "/" + user.id,
				})
				.then(response => {
					window.location.reload(false)
				})
		}
	}

	return (
		<div className={className}>
			<Col md="0" lg="3" xl="4">
				{width > breakPoint && <DesktopMenu />}
			</Col>
			<Col className="layout-col" xs="12" lg="6" xl="4">
				<Row className="layout-row-title">
					{/* {width <= breakPoint && (
						<Col className="burger-button-container">
							<MobileMenu />
						</Col>
					)} */}
					<Col className="burger-button-container">{width <= breakPoint && <MobileMenu />}</Col>
					<Col className="potager-container">
						<img src={Potagers} alt="" />
						<span className="potager-name">
							{user && user.potager && user.potager.nomEntreprise}
						</span>
					</Col>
				</Row>
				<div style={{ position: "absolute", top: "20px", right: "20px", width: "auto" }}>
					<img src={Arrosage} alt="" onClick={() => handleSpray()} style={{ cursor: "pointer" }} />
				</div>
				{children}
			</Col>
			<Col
				md="0"
				lg="3"
				xl="4"
				//  style={{ borderLeft: `1px solid ${Colors.lightBlue}` }}
				className="right-container"
			></Col>
			{width <= breakPoint && <NavigationBar withSpray={withSpray} />}
		</div>
	)
}

export default styled(HubLayout)`
	height: 100vh;
	// width: 100vw;
	background-color: ${Colors.white};
	display: flex;

	.row {
		margin: 0;
		padding: 0;
	}
	.col {
		margin: 0;
		padding: 0;
	}

	.layout-col {
		margin: 0;
		padding: 0;
		flex-direction: column;
		display: flex;
		background-color: ${Colors.white};
	}
	.layout-row-title {
		margin-bottom: 15px;
	}
	.layout-row {
	}
	.burger-button-container {
		min-width: 75px;
		max-width: 75px;
		padding: 0 25px;
	}
	.potager-container {
		display: flex;
		flex-direction: column;
		padding: 40px 0 0;
		img {
			min-width: 150px;
			max-width: 150px;
		}
	}
	.potager-name {
		color: ${Colors.middleGreen};
		font-size: 25px;
		letter-spacing: -0.2px;
	}
	@media only screen and (min-width: ${breakPoint + 1}px) {
		.right-container {
			border-left: 1px solid ${Colors.lightBlue};
		}
	}
`
