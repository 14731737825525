import { BASE_URL, DEV } from "constants/environnement"
// import HttpError from "standard-http-error"
const request = async (method, path, params, token, type = "json") => {
	const headers = new Headers()
	if (token) {
		let user = JSON.parse(localStorage.getItem("user"))
		if (!user) throw new Error("Pas de Bearer")
		headers.delete("Authorization")
		headers.set("Authorization", `Bearer ${user.accessToken}`)
	} else {
		headers.delete("Authorization")
	}

	const init = {
		method,
	}

	if (params) {
		if (params instanceof FormData) {
			init.body = params
		} else {
			headers.set("Content-Type", "application/json")
			init.body = JSON.stringify(params)
		}
	}

	init.headers = headers

	// if (DEV) {
	// 	console.log("⬆️", method, path, [...headers.values()], params || "")
	// }

	let response = null
	try {
		response = await fetch(BASE_URL + path, init)
	} catch (e) {
		console.log(e)
		response = {}
		response.status = 700
	}
	// if (DEV) {
	// 	console.log(response.status >= 500 ? "❗️" : response.status >= 400 ? "⚠️" : "✅", response)
	// }

	if (!response || response.status === 401) {
		return null
	}

	if (response.status === 409) {
		return response.json()
	}

	if (response.status >= 400) {
		throw new Error(response.status, response.statusText)
		// return null
	}

	if (
		response.headers.get("Content-type") === "application/ld+json; charset=utf-8" ||
		response.headers.get("Content-type") === "application/json"
	) {
		return response.status !== 204 ? response.json() : null
	} else {
		return response.status !== 204 ? response.blob() : null
	}
}
const api = {
	get: (path, params) => request("GET", path, params, true),
	post: (path, params) => request("POST", path, params, true),
	put: (path, params) => request("PUT", path, params, true),
	delete: (path, params) => request("DELETE", path, params, true),
	anon: (path, params) => request("POST", path, params, false),
}
export default api
