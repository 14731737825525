import React, { useContext, useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import AuthenticationContext from "contexts/AuthenticationContext"
import { Colors } from "constants/colors"
import { USER_ENDPOINT } from "constants/endpoints"
import { breakPoint } from "constants/front"
import api from "services/api"
import AuthService from "services/auth"
import DesktopLayout from "components/common/DesktopLayout"
import Loader from "components/common/Loader"
import { RegisterButton, RegisterContainer, RegisterFormContainer } from "styles/Register.style"
import Close from "assets/close-white.svg"

const ContenuPassword = ({ className, ...props }) => {
	const [editUser, setEditUser] = useState(null)
	const [message, setMessage] = useState("")
	const [password1, setPassword1] = useState("")
	const [password2, setPassword2] = useState("")

	const { user, setUser } = useContext(AuthenticationContext)

	useEffect(() => {
		if (user) setEditUser(user)
	}, [user])

	useEffect(() => {
		setMessage("")
		if (password1 !== password2) setMessage("Les mots de passe doivent correspondre.")
		if (password1 && password1.length < 8)
			setMessage("Le mot de passe doit faire min. 8 caractères.")
	}, [password1, password2])

	const logOut = () => {
		AuthService.logout()
		setUser(undefined)
	}

	const handleSubmit = event => {
		event.preventDefault()
		if (validateForm()) {
			let body = {
				nom: editUser.nom,
				prenom: editUser.prenom,
				email: editUser.email,
				plainPassword: password1,
			}

			api.put(USER_ENDPOINT + "/" + editUser.id, body).then(
				response => {
					alert("Votre mot de passe a bien été modifiée. Vous allez être déconnecté.")
					logOut()
				},
				error => {
					console.log("error", error)
				}
			)
		}
	}

	const validateForm = () => {
		return password1 === password2 && password1.length > 7
	}

	if (!user || !editUser)
		return (
			<Loader
				backgroundColor={Colors.darkBlueGreen}
				style={{ paddingTop: "150px", height: "calc(100vh - 120px)" }}
			/>
		)

	return (
		<div className="account-container">
			<div className="account-title">Modifier votre mot de passe</div>
			<RegisterContainer>
				<Row
					className="h-100"
					style={{ justifyContent: "center", alignItems: "center", margin: "0" }}
				>
					<RegisterFormContainer onSubmit={handleSubmit} className="login-form">
						<Form.Group
							size="md"
							controlId="email"
							className="form-group"
							style={{ width: "100%", margin: "auto" }}
						>
							<Form.Control
								autoFocus
								type="password"
								value={password1}
								onChange={e => setPassword1(e.target.value)}
								placeholder="Votre mot de passe"
							/>
							<Form.Control
								type="password"
								value={password2}
								onChange={e => setPassword2(e.target.value)}
								placeholder="Confirmez votre mot de passe"
								style={{ marginTop: "10px" }}
							/>
							{message && (
								<div className="form-group">
									<div className="alert alert-danger" role="alert">
										{message}
									</div>
								</div>
							)}
						</Form.Group>
						<div className="form-group" style={{ marginBottom: "0" }}>
							<RegisterButton type="submit" disabled={!validateForm()}>
								Valider
							</RegisterButton>
						</div>
					</RegisterFormContainer>
				</Row>
			</RegisterContainer>
		</div>
	)
}

const Password = ({ className, ...props }) => {
	const [width, setWidth] = useState(window.innerWidth)

	let navigate = useNavigate()

	useEffect(() => {
		const handleResizeWindow = () => setWidth(window.innerWidth)
		window.addEventListener("resize", handleResizeWindow)
		return () => {
			window.removeEventListener("resize", handleResizeWindow)
		}
	}, [])

	if (width > breakPoint)
		return (
			<DesktopLayout>
				<div
					className={className}
					style={{
						minHeight: "calc(100vh - 120px)",
						maxHeight: "calc(100vh - 120px)",
						overflowX: "hidden",
					}}
				>
					<Col md="0" lg="3" xl="4"></Col>
					<Col className="col" xs="12" lg="6" xl="4">
						<ContenuPassword />
					</Col>
					<Col md="0" lg="3" xl="4"></Col>
				</div>
			</DesktopLayout>
		)

	return (
		<div className={className}>
			<Col md="0" lg="3" xl="4"></Col>
			<Col className="layout-col" xs="12" lg="6" xl="4">
				<div onClick={() => navigate(-1)} className="layout-row-close">
					<img src={Close} alt=" " style={{ height: "20px", width: "20px" }} />
				</div>
				<ContenuPassword />
			</Col>
			<Col md="0" lg="3" xl="4" className="right-container"></Col>
		</div>
	)
}

export default styled(Password)`
	min-height: 100vh;
	background-color: ${Colors.darkBlueGreen};
	display: flex;

	.row {
		margin: 0;
		padding: 0;
	}
	.col {
		margin: 0;
		padding: 0;
	}

	.layout-col {
		margin: 0;
		padding: 0;
		flex-direction: column;
		display: flex;
	}

	.layout-row-close {
		padding: 20px;
		display: flex;
		justify-content: flex-end;
		cursor: pointer;
	}

	.account-container {
		padding: 50px 25px 60px;
	}

	.account-title {
		color: ${Colors.white};
		font-size: 35px;
		font-weight: 700;
		line-height: 37px;
		margin-bottom: 100px;
	}

	input {
		color: ${Colors.darkBlueGreen};
		border: none;
		border-radius: 25px;
		padding: 20px;
		width: 100%;
		margin-bottom: 10px;
	}

	.submit {
		margin-top: 25px;
		height: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 25px;
		background-color: ${Colors.lightGreen};
		cursor: pointer;
		color: ${Colors.white};
		font-size: 16px;
		font-weight: 600;
		letter-spacing: -0.12px;
	}

	.logout {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 30px;
	}
`
