import React, { useContext, useState } from "react"
// import { Col, Row, Form } from "react-bootstrap"
// import { useNavigate } from "react-router-dom"
// import AuthenticationContext from "../contexts/AuthenticationContext"
// import AuthService from "../services/auth"
// import { LoginContainer, LoginButton } from "../styles/Login.style.js"
// import Logo from "../assets/logoBL.svg"

const SplashScreen = () => {
	// const [message, setMessage] = useState("")
	// const [username, setUsername] = useState("")
	// const [password, setPassword] = useState("")
	// const [loading, setLoading] = useState(false)

	// const { setUser } = useContext(AuthenticationContext)

	// let navigate = useNavigate()

	// const validateForm = () => {
	// 	return username.length > 0 && password.length > 0
	// }

	// const handleLogin = async event => {
	// 	event.preventDefault()

	// 	await AuthService.login(username, password).then(
	// 		() => {
	// 			navigate("/gestion-potagers")
	// 		},
	// 		error => {
	// 			const resMessage =
	// 				(error.response && error.response.data && error.response.data.message) ||
	// 				error.message ||
	// 				error.toString()

	// 			setMessage(resMessage)
	// 		}
	// 	)
	// 	let user = AuthService.getCurrentUser()
	// 	if (user) setUser(user)
	// }

	return (
		<div>Chargement en cours....</div>
		// <LoginContainer>
		// 	<Row className="h-75" style={{ justifyContent: "center", alignItems: "center", margin: "0" }}>
		// 		<Col xs="12" sm="8" md="6" lg="5" xl="4" xxl="3">
		// 			<Form onSubmit={handleLogin} className="login-form">
		// 				<img
		// 					src={Logo}
		// 					style={{ alignSelf: "center", maxWidth: "200px", marginBottom: "30px" }}
		// 					alt="logo_login"
		// 				></img>
		// 				<Form.Group size="md" controlId="email" className="form-group">
		// 					<Form.Label>Username</Form.Label>
		// 					<Form.Control
		// 						autoFocus
		// 						type="email"
		// 						value={username}
		// 						onChange={e => setUsername(e.target.value)}
		// 					/>
		// 				</Form.Group>
		// 				<Form.Group size="md" controlId="password" className="form-group">
		// 					<Form.Label>Password</Form.Label>
		// 					<Form.Control
		// 						type="password"
		// 						value={password}
		// 						onChange={e => setPassword(e.target.value)}
		// 					/>
		// 				</Form.Group>
		// 				{message && (
		// 					<div className="form-group">
		// 						<div className="alert alert-danger" role="alert">
		// 							{message}
		// 						</div>
		// 					</div>
		// 				)}
		// 				<div className="form-group">
		// 					<LoginButton className="btn" type="submit" disabled={!validateForm()}>
		// 						Connexion
		// 					</LoginButton>
		// 				</div>
		// 			</Form>
		// 		</Col>
		// 	</Row>
		// </LoginContainer>
	)
}

export default SplashScreen
