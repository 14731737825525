import React, { useEffect, useState } from "react"
import { Row, Form } from "react-bootstrap"
import UserLayout from "components/common/UserLayout"
import { RegisterContainer, RegisterButton, RegisterFormContainer } from "styles/Register.style"

const RegisterStepPassword = ({ handlePassword, form, setForm, ...props }) => {
	const [message, setMessage] = useState("")
	const [password1, setPassword1] = useState("")
	const [password2, setPassword2] = useState("")

	useEffect(() => {
		setMessage("")
		if (password1 !== password2) setMessage("Les mots de passe doivent correspondre.")
		if (password1 && password1.length < 8)
			setMessage("Le mot de passe doit faire min. 8 caractères.")
	}, [password1, password2])

	const handleSubmit = event => {
		event.preventDefault()
		if (validateForm) {
			setForm({ ...form, plainPassword: password1 })
			handlePassword()
		}
	}

	const validateForm = () => {
		return password1 === password2 && password1.length > 7
	}

	return (
		<UserLayout title="Créer votre mot de passe">
			<RegisterContainer>
				<Row
					className="h-100"
					style={{ justifyContent: "center", alignItems: "center", margin: "0" }}
				>
					<RegisterFormContainer onSubmit={handleSubmit} className="login-form">
						<Form.Group
							size="md"
							controlId="pass1"
							className="form-group"
							style={{ width: "100%", margin: "auto" }}
						>
							<Form.Control
								autoFocus
								type="password"
								value={password1}
								onChange={e => setPassword1(e.target.value)}
								placeholder="Votre mot de passe"
							/>
							<Form.Control
								type="password"
								value={password2}
								onChange={e => setPassword2(e.target.value)}
								placeholder="Confirmez votre mot de passe"
								style={{ marginTop: "10px" }}
							/>
							{message && (
								<div className="form-group">
									<div className="alert alert-danger" role="alert">
										{message}
									</div>
								</div>
							)}
						</Form.Group>
						<div className="form-group" style={{ marginBottom: "0" }}>
							<RegisterButton type="submit" disabled={!validateForm()}>
								Valider
							</RegisterButton>
						</div>
					</RegisterFormContainer>
				</Row>
			</RegisterContainer>
		</UserLayout>
	)
}

export default RegisterStepPassword
