import React, { useEffect, useState } from "react"
import localization from "moment/locale/fr"
import api from "services/api"
import { DateBox, DescriptionContainer, EventItemContainer } from "styles/Event.style"
import Logo from "assets/logo.svg"

const moment = require("moment")
moment.updateLocale("fr", localization)

const EventItem = ({ event, ...props }) => {
	const [imgSrc, setImgSrc] = useState(null)

	useEffect(() => {
		if (event && event.user && event.user.media) {
			api.get(event.user.media.contentUrl).then(data => {
				const imageObjectURL = URL.createObjectURL(data)
				setImgSrc(imageObjectURL)
			})
		}
	}, [])

	return (
		<EventItemContainer>
			{(event.shared || !event.user) && <img src={Logo} alt="" style={{ width: "25px" }} />}
			{!event.shared && event.user && (
				<img
					src={imgSrc}
					alt=""
					style={{
						height: "40px",
						width: "25px",
						objectFit: "cover",
						marginRight: "10px",
						borderRadius: "12px",
					}}
				/>
			)}
			{event.shared && <DateBox>{moment(event.date).locale("fr").format("DD MMM")}</DateBox>}
			<DescriptionContainer>{event.description}</DescriptionContainer>
		</EventItemContainer>
	)
}

export default EventItem
