import React, { useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { Colors } from "constants/colors"
import { ARTICLE_ENDPOINT } from "constants/endpoints"
import { breakPoint } from "constants/front"
import api from "services/api"
import DesktopLayout from "components/common/DesktopLayout"
import ArticleItem from "components/Hub/ArticleItem"
import DesktopMenu from "components/Navigation/DesktopMenu"
import NavigationBar from "components/Navigation/NavigationBar"
import {
	ContenuArticleContainer,
	RelatedArticlesContainer,
	RubriqueContainer,
} from "styles/Article.style"
import Arrow from "assets/right-arrow.svg"

const ContenuArticle = ({ style, _width, ...props }) => {
	const [article, setArticle] = useState(null)
	const [relatedArticles, setRelatedArticles] = useState([])

	let navigate = useNavigate()
	const { id } = useParams()

	useEffect(() => {
		api.get(ARTICLE_ENDPOINT + "/" + id).then(async data => {
			setArticle(data)
			let _relatedArticles = []
			let randomIndex = 0
			if (data.rubrique) {
				await api.get(ARTICLE_ENDPOINT + "?statut=1&rubrique=" + data.rubrique.id).then(d => {
					randomIndex = Math.floor(Math.random() * (d["hydra:totalItems"] - 1))
					d["hydra:member"] = d["hydra:member"].filter(a => a.id !== data.id)
					if (d["hydra:member"][randomIndex]) _relatedArticles.push(d["hydra:member"][randomIndex])
				})
			}
			if (data.tags) {
				randomIndex = Math.floor(Math.random() * data.tags.length)
				await api
					.get(ARTICLE_ENDPOINT + "?statut=1&tags.titre=" + data.tags[randomIndex].titre)
					.then(d => {
						randomIndex = Math.floor(Math.random() * (d["hydra:totalItems"] - 1))
						d["hydra:member"] = d["hydra:member"].filter(a => a.id !== data.id)
						if (d["hydra:member"][randomIndex])
							_relatedArticles.push(d["hydra:member"][randomIndex])
					})
			}
			setRelatedArticles(_relatedArticles)
			window.scrollTo(0, 0)
			document.querySelector("#article-container")?.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			})
		})
	}, [id])

	return (
		<div className="explorer-container">
			{article && (
				<>
					<div onClick={() => navigate(-1)} className="explorer-arrow">
						<img src={Arrow} alt=" " />
					</div>
					<div className="explorer-title">{article.titre}</div>
					{article && article.vignette && (
						<img
							src={article.vignette.contentUrl}
							alt=""
							style={{
								maxHeight: "400px",
								width: "100%",
								objectFit: "cover",
								background: `linear-gradient(180deg, ${Colors.darkGreen} 60%, ${Colors.white} 40%)`,
								padding: _width > breakPoint ? `0 calc(100vw / 2 - 300px)` : `0 20px`,
							}}
						/>
					)}
					<RubriqueContainer
						style={{
							padding: _width > breakPoint ? `12px calc(100vw / 2 - 300px) 0` : "12px 30px 0",
						}}
					>
						{article.rubrique && article.rubrique.titre.toUpperCase()}
					</RubriqueContainer>
					<ContenuArticleContainer
						style={{
							padding: _width > breakPoint ? `15px calc(100vw / 2 - 300px) 40px` : "15px 20px 40px",
						}}
						dangerouslySetInnerHTML={{
							__html: article.contenu,
						}}
					></ContenuArticleContainer>
					<RelatedArticlesContainer
						style={{
							padding: _width > breakPoint ? `30px calc(100vw / 2 - 300px) 15px` : "30px 20px 80px",
						}}
					>
						{relatedArticles &&
							relatedArticles.map((a, key) => <ArticleItem article={a} key={key} />)}
					</RelatedArticlesContainer>
				</>
			)}
		</div>
	)
}

const Article = ({ className, ...props }) => {
	const [width, setWidth] = useState(window.innerWidth)

	useEffect(() => {
		const handleResizeWindow = () => setWidth(window.innerWidth)
		window.addEventListener("resize", handleResizeWindow)
		return () => {
			window.removeEventListener("resize", handleResizeWindow)
		}
	}, [])

	if (width > breakPoint)
		return (
			<DesktopLayout>
				<div
					className={className}
					style={{
						minHeight: "calc(100vh - 120px)",
						maxHeight: "calc(100vh - 120px)",
						overflowX: "hidden",
					}}
					id="article-container"
				>
					<ContenuArticle _width={width} />
				</div>
			</DesktopLayout>
		)

	return (
		<div className={className}>
			<Col md="0" lg="3" xl="4">
				{width > breakPoint && <DesktopMenu />}
			</Col>
			<Col className="layout-col" xs="12" lg="6" xl="4">
				<ContenuArticle _width={width} />
			</Col>
			<Col md="0" lg="3" xl="4" className="right-container"></Col>
			{width <= breakPoint && <NavigationBar withSpray={false} />}
		</div>
	)
}

export default styled(Article)`
	min-height: 100vh;
	background-color: ${Colors.white};
	display: flex;

	.row {
		margin: 0;
		padding: 0;
	}
	.col {
		margin: 0;
		padding: 0;
	}

	.layout-col {
		margin: 0;
		padding: 0;
		flex-direction: column;
		display: flex;
	}

	.explorer-container {
		width: 100%;
	}

	.explorer-title {
		color: ${Colors.white};
		font-size: 30px;
		font-weight: 700;
		line-height: 32px;
		padding: 20px 25px 30px;
		background-color: ${Colors.darkGreen};
	}

	.explorer-arrow {
		padding: 50px 25px 0;
		background-color: ${Colors.darkGreen};
		:hover {
			cursor: pointer;
		}
	}

	@media only screen and (min-width: ${breakPoint + 1}px) {
		.explorer-title {
			padding: 20px calc(100vw / 2 - 300px) 30px;
		}
		.explorer-arrow {
			padding: 50px calc(100vw / 2 - 300px) 0;
		}
	}
`
