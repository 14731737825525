import React from "react"
import { Col, Row, Form } from "react-bootstrap"
import { Colors } from "constants/colors"
import { CONTACT } from "constants/environnement"
import AnonLayout from "components/common/AnonLayout"
import { RegisterContainer, RegisterButton, NoAccount } from "styles/Register.style"

const RegisterStepCodePotager = ({ handleCodePotager, form, setForm, ...props }) => {
	const validateForm = () => {
		return parseFloat(form.codePotager) === parseInt(form.codePotager)
	}

	return (
		<AnonLayout>
			<RegisterContainer>
				<Row
					className="h-75"
					style={{ justifyContent: "center", alignItems: "center", margin: "0" }}
				>
					<Col xs="12" sm="8" md="6" lg="5" xl="4" xxl="3">
						<Form onSubmit={handleCodePotager} className="login-form">
							<Form.Group size="md" controlId="email" className="form-group">
								<Form.Control
									autoFocus
									type="number"
									value={form.codePotager}
									onChange={e => setForm({ ...form, codePotager: e.target.value })}
									placeholder="Votre code entreprise"
								/>
							</Form.Group>
							<div className="form-group">
								<RegisterButton type="submit" disabled={!validateForm()}>
									Valider
								</RegisterButton>
							</div>
						</Form>
					</Col>
				</Row>
				<Row
					style={{
						display: "flex",
						alignContent: "center",
						backgroundColor: Colors.white,
						height: "60px",
					}}
				>
					<NoAccount>Pas encore de compte ?</NoAccount>
					<NoAccount>
						<a href={`mailto:${CONTACT}`}>Découvrez nos services</a>
					</NoAccount>
				</Row>
			</RegisterContainer>
		</AnonLayout>
	)
}

export default RegisterStepCodePotager
