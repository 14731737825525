import React, { useContext } from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import AuthenticationContext from "contexts/AuthenticationContext"
import { Colors } from "constants/colors"
import { EVENEMENT_ENDPOINT, POTAGER_ENDPOINT, USER_ENDPOINT } from "constants/endpoints"
import api from "services/api"
import DesktopMenu from "components/Navigation/DesktopMenu"
import Potagers from "assets/LesPotagers-Titre.svg"
import Arrosage from "assets/arrosage.svg"
import moment from "moment"

const HubDesktopLayout = ({ className, children, withSpray, ...props }) => {
	const { user } = useContext(AuthenticationContext)

	const handleSpray = () => {
		if (user && window.confirm("Avez-vous arrosé ?")) {
			api
				.post(EVENEMENT_ENDPOINT, {
					description:
						user.prenom +
						" " +
						user.nom +
						" a arrosé le " +
						moment().format("DD MMM YYYY [à] HH:mm"),
					date: moment(),
					statut: true,
					shared: false,
					potager: POTAGER_ENDPOINT + "/" + user.potager?.id,
					user: USER_ENDPOINT + "/" + user.id,
				})
				.then(response => {
					window.location.reload(false)
				})
		}
	}

	return (
		<div className={className}>
			<Row className="layout-row-title">
				<Col className="potager-container">
					<img src={Potagers} alt="" />
					<span className="potager-name">{user && user.potager && user.potager.nomEntreprise}</span>
				</Col>
				<Col>
					<div style={{ display: "flex", justifyContent: "flex-end", paddingRight: "45px" }}>
						<img
							src={Arrosage}
							alt=""
							onClick={() => handleSpray()}
							style={{ cursor: "pointer" }}
						/>
					</div>
				</Col>
			</Row>
			<Row>
				<DesktopMenu />
				<Col className="layout-col">{children}</Col>
			</Row>
		</div>
	)
}

export default styled(HubDesktopLayout)`
	height: 100vh;
	background-color: ${Colors.lightGrey};
	display: flex;
	flex-direction: column;

	.row {
		margin: 0;
		padding: 0;
	}
	.col {
		margin: 0;
		padding: 0;
	}

	.layout-col {
		margin: 0;
		padding: 0;
		flex-direction: column;
		display: flex;
		background-color: ${Colors.white};
	}
	.layout-row-title {
		height: 120px;
		min-height: 120px;
		align-items: center;
		box-shadow: inset -20px -20px 20px -20px rgba(19, 62, 85, 0.26);
	}
	.layout-row {
	}
	.potager-container {
		display: flex;
		flex-direction: column;
		margin-left: 80px;
		img {
			min-width: 150px;
			max-width: 150px;
		}
	}
	.potager-name {
		color: ${Colors.middleGreen};
		font-size: 25px;
		letter-spacing: -0.2px;
	}
`
