import React, { useContext, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { slide as Menu } from "react-burger-menu"
import styled from "styled-components"
import AuthenticationContext from "contexts/AuthenticationContext"
import Router from "router"
import { Colors } from "constants/colors"
import { CONTACT } from "constants/environnement"
import AuthService from "services/auth"
import AccueilIcon from "./Icons/AccueilIcon"
import PartagerIcon from "./Icons/PartagerIcon"
import ExplorerIcon from "./Icons/ExplorerIcon"
import JardinerIcon from "./Icons/JardinerIcon"
import Account from "assets/user-contour.svg"
import Contact from "assets/contact-contour.svg"
import Close from "assets/close-blue.svg"
import Burger from "assets/menu.svg"

const SideBarLink = ({ to, children, closeSideBar, className, style }) => {
	return (
		<NavLink to={to} onClick={closeSideBar} className={className} style={style}>
			{children}
		</NavLink>
	)
}

const MobileMenu = ({ className, ...props }) => {
	const [isOpen, setOpen] = useState(false)

	const { setUser } = useContext(AuthenticationContext)
	let location = useLocation()

	const logOut = () => {
		AuthService.logout()
		setUser(undefined)
	}

	const handleIsOpen = () => {
		setOpen(!isOpen)
	}

	const closeSideBar = () => {
		setOpen(false)
	}

	return (
		<div className={className}>
			<Menu
				customBurgerIcon={<img src={Burger} alt="" />}
				customCrossIcon={<img src={Close} alt="" />}
				isOpen={isOpen}
				onOpen={handleIsOpen}
				onClose={handleIsOpen}
				width="80vw"
			>
				<SideBarLink to={Router.hub.hub} className="nav-link" closeSideBar={closeSideBar}>
					<AccueilIcon
						active={
							location.pathname === Router.hub.hub || location.pathname === Router.hub.partager
						}
					/>
					Accueil
				</SideBarLink>
				<SideBarLink to={Router.partager} className="nav-link" closeSideBar={closeSideBar}>
					<PartagerIcon active={location.pathname === Router.partager} />
					Partager
				</SideBarLink>
				<SideBarLink to={Router.explorer.explorer} className="nav-link" closeSideBar={closeSideBar}>
					<ExplorerIcon active={location.pathname === Router.explorer.explorer} />
					Explorer
				</SideBarLink>
				<SideBarLink to={Router.hub.jardiner} className="nav-link" closeSideBar={closeSideBar}>
					<JardinerIcon active={location.pathname === Router.hub.jardiner} />
					Jardiner
				</SideBarLink>
				<SideBarLink
					to={Router.account.account}
					className="nav-link"
					closeSideBar={closeSideBar}
					style={{ marginTop: "auto", alignItems: "center" }}
				>
					<img src={Account} alt="" />
					Mon compte
				</SideBarLink>
				<a
					href={`mailto:${CONTACT}`}
					className="nav-link"
					onClick={closeSideBar}
					style={{ alignItems: "center" }}
				>
					<img src={Contact} alt="" />
					Contactez-nous
				</a>
			</Menu>
		</div>
	)
}

export default styled(MobileMenu)`
	/* Position and sizing of burger button */
	.bm-burger-button {
		position: absolute;
		width: 25px;
		height: 20px;
		left: 25px;
		top: 55px;
	}

	/* Color/shape of burger icon bars */
	.bm-burger-bars {
		background: ${Colors.darkBlueGreen};
	}

	/* Color/shape of burger icon bars on hover*/
	.bm-burger-bars-hover {
		background: ${Colors.lightBlueGreen};
		opacity: 1 !important;
	}

	/* Position and sizing of clickable cross button */
	.bm-cross-button {
		height: 20px !important;
		width: 20px !important;
		right: 20px !important;
		top: 20px !important;
	}

	/* Color/shape of close button cross */
	.bm-cross {
		background: transparent;
	}

	/*
		Sidebar wrapper styles
		Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
	*/
	.bm-menu-wrap {
		position: fixed;
		height: 100%;
		top: 0;
		left: 0;
	}

	/* General sidebar styles */
	.bm-menu {
		background: ${Colors.white};
		padding: 20px 25px 0;
		font-size: 20px;
	}

	/* Morph shape necessary with bubble or elastic */
	.bm-morph-shape {
		fill: #373a47;
	}

	/* Wrapper for item list */
	.bm-item-list {
		padding: 20px 0;
		display: flex;
		flex-direction: column;
	}

	/* Individual item */
	.bm-item {
		display: flex !important;
		align-items: baseline;
		padding: 20px 0;
		color: ${Colors.darkGreen};
		img {
			margin-right: 15px;
		}
	}

	/* Styling of overlay */
	.bm-overlay {
		background: ${Colors.darkBlueGreen} !important;
		opacity: 0.8 !important;
		top: 0;
		left: 0;
	}
`
