import React from "react"
import { Row, Form } from "react-bootstrap"
import UserLayout from "components/common/UserLayout"
import { RegisterContainer, RegisterButton, RegisterFormContainer } from "styles/Register.style"

const RegisterStepProfil = ({ handleProfil, form, setForm, ...props }) => {
	return (
		<UserLayout title="Créer votre profil">
			<RegisterContainer>
				<Row
					className="h-100"
					style={{ justifyContent: "center", alignItems: "center", margin: "0" }}
				>
					<RegisterFormContainer onSubmit={handleProfil} className="login-form">
						<Form.Group
							size="md"
							controlId="nom"
							className="form-group"
							style={{ width: "100%", margin: "auto", marginBottom: "0" }}
						>
							<Form.Control
								autoFocus
								type="text"
								value={form.nom}
								onChange={e => setForm({ ...form, nom: e.target.value })}
								placeholder="Votre nom"
							/>
						</Form.Group>
						<Form.Group
							size="md"
							controlId="prenom"
							className="form-group"
							style={{ width: "100%", margin: "auto", marginTop: "10px" }}
						>
							<Form.Control
								type="text"
								value={form.prenom}
								onChange={e => setForm({ ...form, prenom: e.target.value })}
								placeholder="Votre prénom"
							/>
						</Form.Group>
						<div className="form-group" style={{ marginBottom: "0" }}>
							<RegisterButton type="submit">Valider</RegisterButton>
						</div>
					</RegisterFormContainer>
				</Row>
			</RegisterContainer>
		</UserLayout>
	)
}

export default RegisterStepProfil
