import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Router from "router"
import api from "services/api"
import { GalerieMediaItemContainerHub } from "styles/GalerieMedia.style"

const GalerieMediaItem = ({ gm, ...props }) => {
	const [imgSrc, setImgSrc] = useState(null)

	useEffect(() => {
		if (gm && gm.media) {
			api.get(gm.media.contentUrl).then(data => {
				const imageObjectURL = URL.createObjectURL(data)
				setImgSrc(imageObjectURL)
			})
		}
	}, [])

	return (
		<GalerieMediaItemContainerHub>
			<Link to={Router.galeriePhoto}>
				<img
					src={imgSrc}
					alt=""
					style={{
						maxHeight: "100px",
						maxWidth: "150px",
						objectFit: "cover",
					}}
				/>
			</Link>
		</GalerieMediaItemContainerHub>
	)
}

export default GalerieMediaItem
