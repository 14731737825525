import React from "react"
import { Row } from "react-bootstrap"
import styled from "styled-components"
import Logo from "assets/logoBL.svg"
import Potager from "assets/splash.png"

const AnonLayout = ({ className, children, title, ...props }) => {
	return (
		<div className={className}>
			<Row className="layout-top-row">
				<img src={Logo} style={{ maxWidth: "200px" }} alt="logo_login"></img>
			</Row>
			<Row className="layout-row">
				<div className="layout-col">{children}</div>
			</Row>
		</div>
	)
}

export default styled(AnonLayout)`
	height: inherit;
	.layout-top-row {
		margin: 0;
		padding: 50px 0;
		background-color: transparent;
		display: flex;
		justify-content: center;
	}
	.layout-row {
		margin: 0;
		height: calc(100vh - 232px);
		display: flex;
		background-image: url(${Potager});
		background-size: cover;
	}
	.layout-col {
		display: flex;
		flex-direction: column;
	}
`
