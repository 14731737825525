import styled from "styled-components"
import { Colors } from "constants/colors"

export const ArticleItemContainer = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: 15px;
	cursor: pointer;
`

export const RubriqueContainer = styled.div`
	color: ${Colors.lightGreen};
	font-size: 13px;
	line-height: 16px;
	letter-spacing: -0.1px;
	margin-bottom: 15px;
	font-weight: 600;
`

export const DescriptionContainer = styled.div`
	color: ${Colors.white};
	font-size: 16px;
	line-height: 16px;
	letter-spacing: -0.12px;
	margin-left: 25px;
	margin-top: 10px;
`

export const ListeRubriquesContainer = styled.div`
	margin-bottom: 15px;
	display: flex;
	flex-wrap: wrap;
	padding: 0 5px;
`

export const FiltreRubrique = styled.div`
	color: ${Colors.lightGreen};
	font-size: 12px;
	font-weight: 500;
	line-height: 14px;
	letter-spacing: -0.09px;
	padding: 0 10px 17px 0;
	:hover {
		cursor: pointer;
	}
`
export const FiltreRubriqueSelected = styled.div`
	color: ${Colors.white};
	font-size: 12px;
	font-weight: 700;
	line-height: 14px;
	letter-spacing: -0.09px;
	padding: 0 10px 17px 0;
	:hover {
		cursor: pointer;
	}
`
export const ArticleUneItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 65px;
	cursor: pointer;
`

export const DescriptionUneContainer = styled.div`
	color: ${Colors.white};
	font-size: 18px;
	line-height: 20px;
	letter-spacing: -0.14px;
	margin: 10px 25px 0;
`
export const RelatedArticlesContainer = styled.div`
	background-color: ${Colors.darkGreen};
	padding: 30px 20px 80px;
`

export const ContenuArticleContainer = styled.div`
	background-color: ${Colors.white};
	padding: 15px 20px 40px;
	img {
		// max-width: calc(100vw - 40px);
		max-width: 100%;
	}
`
