/**
 * Fichier de routing de l'application contenant toutes les routes
 * qu'utilisera React Router.
 */
const Routes = {
	login: `/login`,
	register: `/register`,
	hub: {
		hub: `/mon-potager`,
		partager: `/mon-potager/partager`,
		jardiner: `/jardiner`,
	},
	partager: `/partager`,
	explorer: {
		explorer: `/explorer`,
		article: `/explorer/article/:id`,
	},
	account: {
		account: `/mon-compte`,
		mdpOublie: `/reinitialisation`,
		mdpOublieChange: `/reinitialisation/:token`,
		mdpChange: `/mon-compte/mot-de-passe`,
	},
	galeriePhoto: `/galerie`,
	consents: {
		index: `/consentements`,
		cgu: `/conditions-generales-utilisation`,
		politique: `/politique-confidentialite`,
		mention: `/mentions-legales`,
	},
}

export default Routes
