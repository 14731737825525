import React, { useContext } from "react"
import { NavLink, useLocation } from "react-router-dom"
import AuthenticationContext from "contexts/AuthenticationContext"
import Router from "router"
import { EVENEMENT_ENDPOINT, POTAGER_ENDPOINT, USER_ENDPOINT } from "constants/endpoints"
import api from "services/api"
import AccueilIcon from "./Icons/AccueilIcon"
import ExplorerIcon from "./Icons/ExplorerIcon"
import JardinerIcon from "./Icons/JardinerIcon"
import PartagerIcon from "./Icons/PartagerIcon"
import { BottomBarContainer } from "styles/Navigation.style"
import Arrosage from "assets/arrosage.svg"
import moment from "moment"

const NavigationBar = ({ className, withSpray, ...porps }) => {
	const { user } = useContext(AuthenticationContext)
	let location = useLocation()

	const handleSpray = () => {
		if (user && window.confirm("Avez-vous arrosé ?")) {
			api
				.post(EVENEMENT_ENDPOINT, {
					description:
						user.prenom +
						" " +
						user.nom +
						" a arrosé le " +
						moment().format("DD MMM YYYY [à] HH:mm"),
					date: moment(),
					statut: true,
					shared: false,
					potager: POTAGER_ENDPOINT + "/" + user.potager?.id,
					user: USER_ENDPOINT + "/" + user.id,
				})
				.then(response => {
					window.location.reload(false)
				})
		}
	}

	return (
		<BottomBarContainer>
			<NavLink to={Router.hub.hub} className="nav-link">
				<AccueilIcon
					active={location.pathname === Router.hub.hub || location.pathname === Router.hub.partager}
				/>
			</NavLink>
			<NavLink to={Router.explorer.explorer} className="nav-link">
				<ExplorerIcon active={location.pathname === Router.explorer.explorer} />
			</NavLink>
			<NavLink to={Router.partager} className="nav-link">
				<PartagerIcon active={location.pathname === Router.partager} />
			</NavLink>
			<NavLink to={Router.hub.jardiner} className="nav-link">
				<JardinerIcon active={location.pathname === Router.hub.jardiner} />
			</NavLink>
			{withSpray && (
				<div style={{ position: "absolute", top: "-25px", right: "10px", width: "auto" }}>
					<img src={Arrosage} alt="" onClick={() => handleSpray()} style={{ cursor: "pointer" }} />
				</div>
			)}
		</BottomBarContainer>
	)
}

export default NavigationBar
