import styled from "styled-components"
import { Colors } from "constants/colors"

export const LoginContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	input {
		background-color: ${Colors.white};
		padding: 16px;
		border-radius: 25px;
		color: ${Colors.darkGreen};
	}

	.form-group {
		margin: 10px 0;
	}
`

export const LoginButton = styled.button`
	background-color: ${Colors.lightGreen};
	color: ${Colors.white};
	border: none;
	border-radius: 25px;
	cursor: pointer;
	width: 100%;
	padding: 16px;
	font-weight: 700;
	text-align: left;
	margin-top: 50px;
`

export const ForgotPass = styled.span`
	color: ${Colors.white};
	font-size: 12px;
	line-height: 15px;
	letter-spacing: -0.09px;
	cursor: pointer;
	text-align: left;
	text-align: right;
	text-decoration: underline;
	width: auto;
	margin-bottom: 20px;
`

export const NoAccount = styled.div`
	color: ${Colors.darkGreen};
	font-size: 20px;
	line-height: 20px;
	letter-spacing: -0.16px;
	text-align: center;
	height: 20px;

	a {
		color: ${Colors.darkGreen};
	}
`
