import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { REGISTER } from "constants/endpoints"
import api from "services/api"
import RegisterStepCodePotager from "./RegisterStepCodePotager"
import RegisterStepConsent from "./RegisterStepConsent"
import RegisterStepEmail from "./RegisterStepEmail"
import RegisterStepPassword from "./RegisterStepPassword"
import RegisterStepPhoto from "./RegisterStepPhoto"
import RegisterStepProfil from "./RegisterStepProfil"
import Routes from "router"
import Loader from "components/common/Loader"
import { Colors } from "constants/colors"

const Register = () => {
	const [form, setForm] = useState({
		codePotager: null,
		email: "",
		plainPassword: "",
	})
	const [step, setStep] = useState(1)
	const [loading, setLoading] = useState(false)

	let navigate = useNavigate()

	const handleCodePotager = async event => {
		event.preventDefault()
		// // TODO : vérifier si CodePotager correspond à une entreprise non archivée (pas de bearer)
		// api.get(POTAGER_ENDPOINT + "?codePotager=" + form.CodePotager).then(data => {
		// 	console.log(data)
		// })
		setStep(2)
	}

	const handleEmail = async event => {
		event.preventDefault()
		setStep(3)
	}

	const handlePassword = async => {
		setStep(4)
	}

	const handleProfil = async => {
		setStep(5)
	}

	const handlePhoto = async event => {
		event.preventDefault()
		setStep(6)
	}

	const handleConsent = async event => {
		event.preventDefault()
		setLoading(true)
		submitRegister()
	}

	const submitRegister = async => {
		console.log(form)
		const data = new FormData()
		data.append("email", form.email)
		data.append("plainPassword", form.plainPassword)
		data.append("codePotager", form.codePotager)
		data.append("nom", form.nom)
		data.append("prenom", form.prenom)
		data.append("media", form.media)
		data.append("acceptCGU", form.acceptCGU ? 1 : 0)
		data.append("acceptPolitiqueConf", form.acceptPolitiqueConf ? 1 : 0)
		data.append("acceptMentionLegale", form.acceptMentionLegale ? 1 : 0)
		console.log(data)
		api.anon(REGISTER, data).then(
			response => {
				if (response.error) {
					setLoading(false)
					window.alert("Création impossible :\n" + response.error)
					switch (response.code) {
						case 1:
							setStep(1)
							break
						case 2:
							setStep(1)
							break
						case 3:
							setStep(2)
							break
						case 4:
							setStep(2)
							break
						case 5:
							setStep(3)
							break

						default:
							setStep(1)
							break
					}
				} else {
					setLoading(false)
					window.alert(
						"Votre compte utilisateur a été créé. Veuillez l'activer via le lien de confirmation qui vous a été envoyé par email."
					)
					navigate(Routes.login)
				}
			},
			error => {
				setLoading(false)
				window.alert("Création impossible")
			}
		)
	}

	return (
		<>
			{loading && (
				<Loader
					mini={false}
					backgroundColor={Colors.darkBlueGreen05}
					style={{ position: "absolute", width: "100vw", alignItems: "center" }}
				/>
			)}
			{step === 1 && (
				<RegisterStepCodePotager
					handleCodePotager={handleCodePotager}
					form={form}
					setForm={setForm}
				/>
			)}
			{step === 2 && <RegisterStepEmail handleEmail={handleEmail} form={form} setForm={setForm} />}
			{step === 3 && (
				<RegisterStepPassword handlePassword={handlePassword} form={form} setForm={setForm} />
			)}
			{step === 4 && (
				<RegisterStepProfil handleProfil={handleProfil} form={form} setForm={setForm} />
			)}
			{step === 5 && <RegisterStepPhoto handlePhoto={handlePhoto} form={form} setForm={setForm} />}
			{step === 6 && (
				<RegisterStepConsent handleConsent={handleConsent} form={form} setForm={setForm} />
			)}
		</>
	)
}

export default Register
