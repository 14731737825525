import styled from "styled-components"
import { Colors } from "constants/colors"

export const RegisterContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;

	input {
		background-color: ${Colors.white};
		padding: 16px;
		border-radius: 25px;
		color: ${Colors.darkGreen};
	}

	.form-group {
		margin: 10px 0;
	}
`

export const RegisterButton = styled.button`
	background-color: ${Colors.lightGreen};
	color: ${Colors.white};
	border: none;
	border-radius: 25px;
	cursor: pointer;
	width: 100%;
	padding: 16px;
	font-weight: bolder;
	text-align: left;
	margin-top: 50px;

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}
`

export const NoAccount = styled.div`
	color: ${Colors.darkGreen};
	font-size: 16px;
	line-height: 15px;
	letter-spacing: -0.12px;
	text-align: center;
	height: 15px;

	a {
		color: ${Colors.darkGreen};
	}
`

export const RegisterFormContainer = styled.form`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.preview-pp {
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			height: 170px;
			width: 105px;
			object-fit: cover;
			border-radius: 52px;
		}
	}

	.input-media-pp {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	.input-media-pp + label {
		cursor: pointer;
	}

	input[type="checkbox"] {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	div.checkmark {
		height: 20px;
		width: 20px;
		min-width: 20px;
		background-color: ${Colors.darkBlueGreen};
		border-radius: 5px;
		border: 1px solid white;
		cursor: pointer;
		&.checked {
			background-color: ${Colors.brightGreen};
			border: 2px solid white;
			border-radius: 3px;
		}
	}

	label {
		font-size: 16px;
		line-height: 20px;
		color: ${Colors.white};
		letter-spacing: -0.12px;
		margin-left: 16px;
	}
`

export const ClearPicture = styled.span`
	color: ${Colors.white};
	font-size: 12px;
	line-height: 15px;
	letter-spacing: -0.09px;
	text-align: center;
	text-decoration: underline;
	margin-top: 12px;
	cursor: pointer;
`

export const SkipStep = styled.span`
	color: ${Colors.white};
	font-size: 12px;
	line-height: 15px;
	letter-spacing: -0.09px;
	text-align: right;
	text-decoration: underline;
	margin-top: 12px;
	cursor: pointer;
`
