import React, { useContext, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import AuthenticationContext from "contexts/AuthenticationContext"
import Router from "router"
import { WHO_AM_I } from "constants/endpoints"
import api from "services/api"
import AuthService from "services/auth"

const CheckLoggedIn = ({ className, ...porps }) => {
	const { user, setUser } = useContext(AuthenticationContext)

	let navigate = useNavigate()
	let location = useLocation()

	useEffect(() => {
		let nowTimestamp = Math.floor(Date.now() / 1000)
		if (!user) {
			let u = AuthService.getCurrentUser()
			if (u) {
				if (u.exp < nowTimestamp) {
					logOut()
					navigate(Router.login)
				}
				api.get(WHO_AM_I).then(data => {
					if (data) {
						setUser(data)
					} else {
						logOut()
						navigate(Router.login)
					}
				})
			} else {
				if (
					location.pathname !== Router.register &&
					!location.pathname.startsWith(Router.account.mdpOublie)
				)
					navigate(Router.login)
			}
		} else {
			if (user.exp < nowTimestamp) {
				logOut()
			}
			if (
				location.pathname === Router.login ||
				location.pathname === Router.register ||
				location.pathname === Router.account.mdpOublie ||
				location.pathname === Router.account.mdpOublieChange
			)
				navigate(Router.hub.hub)
		}
	}, [user])

	const logOut = () => {
		AuthService.logout()
		setUser(undefined)
	}

	return <></>
}

export default CheckLoggedIn
