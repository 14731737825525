export const LOGIN = "/api/login_check/"
export const REGISTER = "/api/register"
export const RESET = "/api/reset_password"
export const RESET_CHANGE = "/api/reset_password/change"
export const WHO_AM_I = "/api/who_am_i/"
export const USER_ENDPOINT = "/api/users"
export const POTAGER_ENDPOINT = "/api/potagers"
export const PLANTE_ENDPOINT = "/api/plantes"
export const EVENEMENT_ENDPOINT = "/api/evenements"
export const RUBRIQUE_ENDPOINT = "/api/rubriques"
export const TAG_ENDPOINT = "/api/tags"
export const ARTICLE_ENDPOINT = "/api/articles"
export const EDITO_ENDPOINT = "/api/editos"
export const GALERIE_MEDIA_ENDPOINT = "/api/galerie_media"
export const MEDIA_OBJECT_ENDPOINT = "/api/media_objects"
export const PUBLIC_MEDIA_OBJECT_ENDPOINT = "/api/public_media_objects"
export const ARTICLE_MEDIA_ENDPOINT = "/api/article_media"
