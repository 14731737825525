import React, { useContext, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import localization from "moment/locale/fr"
import styled from "styled-components"
import AuthenticationContext from "contexts/AuthenticationContext"
import Router from "router"
import { Colors } from "constants/colors"
import {
	ARTICLE_ENDPOINT,
	EDITO_ENDPOINT,
	EVENEMENT_ENDPOINT,
	GALERIE_MEDIA_ENDPOINT,
	MEDIA_OBJECT_ENDPOINT,
	POTAGER_ENDPOINT,
} from "constants/endpoints"
import { CONTACT } from "constants/environnement"
import api from "services/api"
import FileUtils from "services/file"
import DesktopLayout from "components/common/DesktopLayout"
import Loader from "components/common/Loader"
import EventItem from "./EventItem"
import GalerieMediaItem from "./GalerieMediaItem"
import Plus from "assets/plus-contour.svg"
import Alert from "assets/alert.svg"
import JardinerFileUploader from "./JardinerFileUploader/JardinerFileUploader"

const moment = require("moment")
moment.updateLocale("fr", localization)

const HubDesktop = ({ className, ...props }) => {
	const [galerieMedias, setGalerieMedias] = useState([])
	const [plantes, setPlantes] = useState([])
	const [edito, setEdito] = useState(null)
	const [fiches, setFiches] = useState([])
	const [events, setEvents] = useState([])
	const [isLoadingJardiner, setIsLoadingJardiner] = useState(true)
	const [isLoadingPartager, setIsLoadingPartager] = useState(true)
	const [toggleReload, setToggleReload] = useState(false)

	const { user } = useContext(AuthenticationContext)

	let navigate = useNavigate()

	useEffect(() => {
		async function fetchData() {
			if (user && user.potager)
				await api.get(POTAGER_ENDPOINT + "/" + user.potager.id).then(data => {
					setPlantes(data.plantes)
				})
			await api
				.get(GALERIE_MEDIA_ENDPOINT + "?itemsPerPage=5&page=1&order[createdAt]=desc")
				.then(data => {
					setGalerieMedias(data["hydra:member"])
				})

			await api
				.get(
					EDITO_ENDPOINT +
						"?mois=" +
						moment().locale("fr").format("MMMM") +
						"&annee=" +
						moment().format("YYYY") +
						"&itemsPerPage=1&page=1&order[updatedAt]=desc"
				)
				.then(data => {
					if (data && data["hydra:member"] && data["hydra:member"][0])
						setEdito(data["hydra:member"][0])
				})

			await api
				.get(
					ARTICLE_ENDPOINT +
						"?tags.titre[]=" +
						moment().locale("fr").format("MMMM") +
						"&tags.titre[]=" +
						moment().format("YYYY") +
						"&statut=1&itemsPerPage=3&page=1&order[updatedAt]=desc"
				)
				.then(data => {
					setFiches(data["hydra:member"])
				})
			setIsLoadingJardiner(false)
		}
		fetchData()
	}, [user, toggleReload])

	useEffect(() => {
		const fetchData = async () => {
			await api
				.get(EVENEMENT_ENDPOINT + "?itemsPerPage=6&page=1&order[createdAt]=desc")
				.then(data => {
					setEvents(data["hydra:member"])
				})
			setIsLoadingPartager(false)
		}
		fetchData()
	}, [])

	const customUpload = files => {
		const file = files[0]
		if (file) {
			FileUtils.uploadFile(MEDIA_OBJECT_ENDPOINT, file).then(({ id }) => {
				api
					.post(GALERIE_MEDIA_ENDPOINT, {
						media: MEDIA_OBJECT_ENDPOINT + "/" + id,
						potager: POTAGER_ENDPOINT + "/" + user?.potager?.id,
					})
					.then(response => {
						setIsLoadingJardiner(true)
						setToggleReload(!toggleReload)
					})
			})
		}
	}

	return (
		<DesktopLayout>
			<Row className="layout-row">
				<Col sm="6" className="col">
					<div className={className}>
						{isLoadingPartager ? (
							<div className="loading-container">
								<Loader
									mini={false}
									backgroundColor={Colors.darkBlueGreen}
									style={{ paddingTop: "150px", height: "calc(100vh - 120px)" }}
								/>
							</div>
						) : (
							<div className="partager-container">
								<span className="partager-title">Partager</span>
								{events && events.map((e, key) => <EventItem event={e} key={key} />)}
								<Link to={Router.partager} className="see-more-link">
									Voir plus
								</Link>
							</div>
						)}
					</div>
				</Col>
				<Col sm="6" className="col">
					<div className={className}>
						{isLoadingJardiner ? (
							<div className="loading-container">
								<Loader
									mini={false}
									backgroundColor={Colors.middleBlueGreen}
									style={{ paddingTop: "150px", height: "calc(100vh - 120px)" }}
								/>
							</div>
						) : (
							<div className="jardiner-container">
								<span className="jardiner-title">Jardiner</span>
								<div className="galerie-container">
									<JardinerFileUploader
										extensions={[".jpg", ".jpeg", ".png"]}
										maxSize={2}
										onFilesChanged={customUpload}
									/>
									{galerieMedias &&
										galerieMedias.map((e, key) => <GalerieMediaItem gm={e} key={key} />)}
								</div>
								<div className="plantes-container">
									<span className="title">Nos plantes du moment</span>
									<div
										className="plante-table"
										style={{
											display: "grid",
											gridTemplateColumns: "repeat(auto-fit, 110px)",
											justifyContent: "center",
										}}
									>
										{plantes &&
											plantes.map((p, key) => (
												<div key={key} className="plante-item">
													<Col className="plante-icon">
														{p.picto && (
															<img
																src={p.picto.contentUrl}
																alt=""
																style={{
																	maxHeight: "35px",
																	maxWidth: "35px",
																	objectFit: "cover",
																}}
															/>
														)}
													</Col>
													<Col>{p.nom}</Col>
												</div>
											))}
									</div>
									<div style={{ padding: "20px 20px 0", alignSelf: "flex-end" }}>
										<a href={`mailto:${CONTACT}`} style={{ display: "flex", maxWidth: "90px" }}>
											<img src={Alert} alt="" style={{ marginRight: "8px" }} />
											Signaler un problème
										</a>
									</div>
								</div>
								{edito ? (
									<div className="edito-container">
										<span className="edito-title">{edito.titre}</span>
										<p className="edito-text">{edito.description}</p>
									</div>
								) : (
									<div className="edito-container">
										<p className="edito-text">Pas d'édito pour ce mois ci ;)</p>
									</div>
								)}
								{fiches && (
									<div className="fiches-container">
										<span className="fiches-title">FICHES PRATIQUES</span>
										<div className="fiches-list-container">
											{fiches.map((f, key) => (
												<div
													key={key}
													className="fiche"
													onClick={() => navigate(Router.explorer.article.replace(":id", f.id))}
												>
													{f.titre}
												</div>
											))}
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</Col>
			</Row>
		</DesktopLayout>
	)
}

export default styled(HubDesktop)`
	padding: 0;
	height: calc(100vh - 120px);
	.partager-container {
		height: inherit;
		display: flex;
		flex-direction: column;
		background-color: ${Colors.darkBlueGreen};
		padding: 30px 50px;
		overflow-x: hidden;

		/* width */
		::-webkit-scrollbar {
			width: 8px;
		}

		/* Track */
		::-webkit-scrollbar-track {
			background-color: rgba(0, 0, 0, 0.05);
		}

		/* Handle */
		::-webkit-scrollbar-thumb {
			background: rgba(0, 0, 0, 0.1);
			border-radius: 10px;
		}

		/* Handle on hover */
		::-webkit-scrollbar-thumb:hover {
			background: rgba(0, 0, 0, 0.15);
		}
	}
	.partager-title {
		margin-bottom: 45px;
		color: ${Colors.white};
		font-family: Lettown Hills;
		font-size: 25px;
		line-height: 37px;
		letter-spacing: -0.2px;
	}
	.see-more-link {
		color: ${Colors.white};
		font-size: 12px;
		line-height: 14px;
		letter-spacing: -0.09px;
		text-align: right;
		margin-top: 10px;
	}
	.jardiner-container {
		height: inherit;
		display: flex;
		flex-direction: column;
		background-color: ${Colors.middleBlueGreen};
		padding: 30px;
		overflow-x: hidden;

		/* width */
		::-webkit-scrollbar {
			width: 8px;
		}

		/* Track */
		::-webkit-scrollbar-track {
			background-color: rgba(0, 0, 0, 0.05);
		}

		/* Handle */
		::-webkit-scrollbar-thumb {
			background: rgba(0, 0, 0, 0.1);
			border-radius: 10px;
		}

		/* Handle on hover */
		::-webkit-scrollbar-thumb:hover {
			background: rgba(0, 0, 0, 0.15);
		}
	}
	.jardiner-title {
		margin-bottom: 45px;
		color: ${Colors.white};
		font-family: Lettown Hills;
		font-size: 25px;
		line-height: 37px;
		letter-spacing: -0.2px;
		padding: 0 20px;
	}
	.galerie-container {
		display: flex;
		flex-direction: row;
		align-items: baseline;
		background-color: ${Colors.middleBlueGreen};
		max-width: calc(100vw);
		width: inherit;
		overflow-x: auto;
		min-height: 100px;
		margin-left: 20px;

		/* width */
		::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}

		/* Track */
		::-webkit-scrollbar-track {
			background-color: rgba(0, 0, 0, 0.05);
		}

		/* Handle */
		::-webkit-scrollbar-thumb {
			background: rgba(0, 0, 0, 0.1);
			border-radius: 10px;
		}

		/* Handle on hover */
		::-webkit-scrollbar-thumb:hover {
			background: rgba(0, 0, 0, 0.15);
		}
	}
	.plantes-container {
		display: flex;
		flex-direction: column;
		padding: 20px 0 30px;
		.title {
			font-size: 20px;
			line-height: 25px;
			letter-spacing: -0.16px;
			color: ${Colors.white};
			font-weight: 600;
			margin-bottom: 20px;
			margin-left: 20px;
		}
		.plante-table {
			background: linear-gradient(${Colors.darkBlueGreen} 50%, ${Colors.middleBlueGreen} 50%);
			background-size: 100% 80px;
			padding: 0 10px;

			.plante-item {
				height: 40px;
				color: ${Colors.white};
				font-size: 14px;
				line-height: 15px;
				letter-spacing: -0.11px;
				display: flex;
				align-items: center;

				.plante-icon {
					margin-right: 10px;
					min-width: 30px;
					max-width: 30px;
					display: flex;
					justify-content: center;
				}
			}
		}

		a {
			font-size: 12px;
			line-height: 12px;
			letter-spacing: -0.09px;
			color: ${Colors.white};
			text-decoration: none;
		}
	}
	.explorer-title {
		color: ${Colors.white};
		font-family: Lettown Hills;
		font-size: 25px;
		line-height: 37px;
		letter-spacing: -0.2px;
		margin-bottom: 30px;
	}
	.edito-container {
		display: flex;
		flex-direction: column;
		background-color: ${Colors.lightBlueGreen};
		padding: 40px 25px 0px;

		.edito-title {
			font-size: 20px;
			line-height: 25px;
			letter-spacing: -0.16px;
			font-weight: 600;
			color: ${Colors.white};
			margin-bottom: 20px;
		}

		.edito-text {
			font-size: 16px;
			line-height: 19px;
			letter-spacing: -0.12px;
			font-weight: 500;
			color: ${Colors.white};
			margin: 0;
		}
	}
	.fiches-container {
		display: flex;
		flex-direction: column;
		background-color: ${Colors.lightBlueGreen};
		padding: 30px 20px;

		.fiches-title {
			font-size: 13px;
			line-height: 16px;
			letter-spacing: -0.1px;
			font-weight: 600;
			color: ${Colors.lightGreen};
			margin-bottom: 15px;
		}

		.fiches-list-container {
			display: flex;
			flex-direction: row;
			padding: 0 5px;
			justify-content: space-between;
			max-width: calc(100vw);
			width: inherit;
			overflow-y: hidden;

			.fiche {
				font-size: 18px;
				line-height: 18px;
				letter-spacing: -0.14px;
				font-weight: 500;
				color: ${Colors.white};
				border-right: 1px solid white;
				margin-left: 15px;
				width: 100%;
				cursor: pointer;
				padding-right: 15px;
			}
			.fiche:first-child {
				margin-left: 0;
			}
			.fiche:last-child {
				padding-right: 0;
				border: none;
			}
		}
	}
`
