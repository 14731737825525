import React, { useContext, useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import AuthenticationContext from "contexts/AuthenticationContext"
import Routes from "router"
import { Colors } from "constants/colors"
import { MEDIA_OBJECT_ENDPOINT, USER_ENDPOINT } from "constants/endpoints"
import api from "services/api"
import AuthService from "services/auth"
import FileUtils from "services/file"
import Loader from "components/common/Loader"
import { ClearPicture } from "styles/Register.style"
import Add from "assets/plus-big.svg"
import AccountLayout from "components/common/AccountLayout"

const Account = ({ className, ...props }) => {
	const [editUser, setEditUser] = useState(null)
	const [imgSrc, setImgSrc] = useState("")

	const { user, setUser } = useContext(AuthenticationContext)

	let navigate = useNavigate()

	useEffect(() => {
		setImgSrc(null)
		if (user) setEditUser(user)
		if (user && user.media && user.media.contentUrl)
			api.get(user.media.contentUrl).then(
				response => {
					const imageObjectURL = URL.createObjectURL(response)
					setImgSrc(imageObjectURL)
				},
				error => {
					console.log("error", error)
				}
			)
	}, [user])

	const logOut = () => {
		AuthService.logout()
		setUser(undefined)
	}

	const onImageChange = event => {
		FileUtils.uploadFile(MEDIA_OBJECT_ENDPOINT, event.target.files[0]).then(({ id }) => {
			setEditUser({ ...editUser, media: MEDIA_OBJECT_ENDPOINT + "/" + id })
			setImgSrc(URL.createObjectURL(event.target.files[0]))
		})
	}

	const handleSubmit = () => {
		let body = {
			nom: editUser.nom,
			prenom: editUser.prenom,
			email: editUser.email,
		}
		if (!editUser.media) body.media = null
		if (editUser.media && !editUser.media.filename) body.media = editUser.media

		api.put(USER_ENDPOINT + "/" + editUser.id, body).then(
			response => {
				if (editUser.email !== user.email) {
					alert("Votre adresse email a bien été modifiée. Vous allez être déconnecté.")
					logOut()
				} else {
					window.location.reload()
				}
			},
			error => {
				console.log("error", error)
			}
		)
	}

	if (!user || !editUser)
		return (
			<Loader
				backgroundColor={Colors.darkBlueGreen}
				style={{ paddingTop: "150px", height: "calc(100vh - 120px)" }}
			/>
		)

	return (
		<AccountLayout>
			<div className="account-container">
				<div className="account-title">Votre compte personnel</div>
				{imgSrc ? (
					<div className="preview-pp">
						<img src={imgSrc} alt=" " />
						<ClearPicture
							onClick={() => {
								setImgSrc(null)
							}}
						>
							Modifier
						</ClearPicture>
					</div>
				) : (
					<div className="preview-pp">
						<input
							onChange={onImageChange}
							type="file"
							name="file"
							id="file"
							placeholder="Photo de profil"
							className="input-media-pp"
						></input>
						<label htmlFor="file">
							<img src={Add} alt="" />
						</label>
					</div>
				)}
				<table className="account-table">
					<tbody>
						<tr>
							<th>Prénom</th>
							<td>
								<input
									type="text"
									maxLength="255"
									value={editUser.prenom}
									onChange={e =>
										setEditUser({
											...editUser,
											prenom: e.target.value,
										})
									}
								/>
							</td>
						</tr>
						<tr>
							<th>Nom</th>
							<td>
								<input
									type="text"
									maxLength="255"
									value={editUser.nom}
									onChange={e =>
										setEditUser({
											...editUser,
											nom: e.target.value,
										})
									}
								/>
							</td>
						</tr>
						<tr>
							<th>Société</th>
							<td>{editUser.potager?.nomEntreprise}</td>
						</tr>
						<tr>
							<th>E-mail</th>
							<td>
								<input
									type="text"
									maxLength="180"
									value={editUser.email}
									onChange={e =>
										setEditUser({
											...editUser,
											email: e.target.value,
										})
									}
								/>
							</td>
						</tr>
						<tr>
							<th>Mot de passe</th>
							<td style={{ whiteSpace: "pre" }}>
								<div style={{ fontWeight: "700", fontSize: "23px", letterSpacing: "-5px" }}>
									••••••••••
								</div>
								<ClearPicture onClick={() => navigate(Routes.account.mdpChange)}>
									Modifier votre mot de passe
								</ClearPicture>
							</td>
						</tr>
						<tr>
							{/* TODO : register : cliquer sur accepter doit cocher la case en question */}
							<th
								colSpan="2"
								onClick={() => navigate(Routes.consents.index)}
								style={{ cursor: "pointer", color: Colors.white, fontWeight: "400" }}
							>
								Mes consentements
							</th>
						</tr>
					</tbody>
				</table>
				{(editUser.prenom !== user.prenom ||
					editUser.nom !== user.nom ||
					editUser.email !== user.email ||
					editUser.media !== user.media) && (
					<div className="submit" onClick={() => handleSubmit()}>
						Valider vos changements
					</div>
				)}
				<div className="logout">
					<a href="/login" onClick={() => logOut()} style={{ marginTop: "auto" }}>
						<ClearPicture>Se déconnecter</ClearPicture>
					</a>
				</div>
			</div>
		</AccountLayout>
	)
}

export default Account
