import React from "react"
import Plus from "assets/plus-contour.svg"
import { FileUploaderContainer } from "./JardinerFileUploader.style"

const JardinerFileUploader = ({ extensions, maxSize, onFilesChanged }) => {
	const handleFilesChanged = async e => {
		let files = Array.from(e.target.files)
		// On vérifie que les fichiers respectent les conditions
		for (const file of files) {
			const extension = `.${file.name.toLowerCase().split(".").pop()}`
			const size = file.size
			// On vérifie que l'extension du fichier est autorisée
			const isValidExtension = extensions.includes(extension)
			// On vérifie que la taille du fichier est inférieure à la taille maximale en octet
			const isValidSize = size <= maxSize * 1000 * 1000
			// Si une de ces conditions n'est pas respectée, on avertit l'utilisateur et on supprime le fichier.
			if (!isValidExtension || !isValidSize) {
				let message = !isValidExtension
					? `Le fichier "${file.name}" n'est pas un format accepté.`
					: `Le fichier dépasse la taille autorisée de ${maxSize}Mo.`
				message += `\nExtensions autorisées : ${extensions.join(
					", "
				)} - Taille maximale : ${maxSize}Mo`
				alert(message)
				files = files.filter(f => f.name !== file.name)
			}
		}
		// On renvoi au parent la liste de fichiers corrects
		onFilesChanged(files)
	}

	return (
		<FileUploaderContainer>
			<label htmlFor="file-upload">
				<img
					src={Plus}
					alt="+"
					title={`Extensions autorisées : ${extensions.join(
						", "
					)} - Taille maximale : ${maxSize}Mo`}
				/>
				<input
					hidden
					id="file-upload"
					type="file"
					multiple={false}
					accept="image/*"
					capture
					onChange={handleFilesChanged}
				></input>
			</label>
		</FileUploaderContainer>
	)
}

export default JardinerFileUploader
