import styled from "styled-components"
import { Colors } from "constants/colors"

export const TabTitleContainer = styled.div`
	display: flex;
	justify-content: center;
	background-color: ${Colors.darkBlueGreen};
	padding: 11px 0;
	position: relative;
	height: 59px;
	width: 100%;
	// z-index: -1;
	&.active {
		background-color: ${Colors.white};
	}
`

export const PartagerTabTitle = styled.div`
	display: flex;
	justify-content: center;
	background-color: ${Colors.white};
	padding: 11px 0;
	position: absolute;
	top: 0;
	height: 59px;
	width: 100%;
	z-index: 2;
	cursor: pointer;

	color: ${Colors.darkBlueGreen};
	font-family: Lettown Hills;
	font-size: 25px;
	line-height: 37px;
	letter-spacing: -0.2px;
	border-radius: 0 0 40px / 35px;
	&.active {
		background-color: ${Colors.darkBlueGreen};
		color: ${Colors.white};
		border-radius: 0;
		border-top-right-radius: 40px 35px;
	}
`

export const JardinerTabTitle = styled.div`
	display: flex;
	justify-content: center;
	background-color: ${Colors.white};
	padding: 11px 0;
	position: absolute;
	top: 0;
	height: 59px;
	width: 100%;
	z-index: 2;
	cursor: pointer;

	color: ${Colors.darkBlueGreen};
	font-family: Lettown Hills;
	font-size: 25px;
	line-height: 37px;
	letter-spacing: -0.2px;
	border-radius: 0 0 0 40px / 35px;
	&.active {
		background-color: ${Colors.darkBlueGreen};
		color: ${Colors.white};
		border-radius: 0;
		border-top-left-radius: 40px 35px;
	}
`
