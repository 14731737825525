import React from "react"
import { Row, Form } from "react-bootstrap"
import UserLayout from "components/common/UserLayout"
import { RegisterContainer, RegisterButton, RegisterFormContainer } from "styles/Register.style"

const RegisterStepEmail = ({ handleEmail, form, setForm, ...props }) => {
	return (
		<UserLayout title="Créer votre compte">
			<RegisterContainer>
				<Row
					className="h-100"
					style={{ justifyContent: "center", alignItems: "center", margin: "0" }}
				>
					<RegisterFormContainer onSubmit={handleEmail} className="login-form">
						<Form.Group
							size="md"
							controlId="email"
							className="form-group"
							style={{ width: "100%", margin: "auto" }}
						>
							<Form.Control
								autoFocus
								type="email"
								value={form.email}
								onChange={e => setForm({ ...form, email: e.target.value })}
								placeholder="Saisir une adresse e-mail"
							/>
						</Form.Group>
						<div className="form-group" style={{ marginBottom: "0" }}>
							<RegisterButton type="submit">Valider</RegisterButton>
						</div>
					</RegisterFormContainer>
				</Row>
			</RegisterContainer>
		</UserLayout>
	)
}

export default RegisterStepEmail
