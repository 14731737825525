import styled from "styled-components"
import { Colors } from "constants/colors"

export const EventItemContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 12px;
	background-color: ${Colors.white};
	border-radius: 25px;
	margin-bottom: 10px;
	min-height: 69px;
	:last-child {
		margin-bottom: 50px;
	}
`

export const DateBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 30px;
	height: 30px;
	min-width: 30px;
	width: 30px;
	background-color: ${Colors.darkBlueGreen};
	border-radius: 12px;
	color: ${Colors.white};
	font-size: 10px;
	line-height: 8px;
	letter-spacing: -0.08px;
	margin-left: 11px;
	text-align: center;
	flex-direction: column;
`

export const DescriptionContainer = styled.div`
	color: ${Colors.darkGreen};
	font-size: 16px;
	line-height: 16px;
	letter-spacing: -0.12px;
	margin-left: 25px;
`
