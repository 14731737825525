import React, { useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Colors } from "constants/colors"
import { breakPoint } from "constants/front"
import DesktopLayout from "./DesktopLayout"
import Close from "assets/close-white.svg"

const AccountLayout = ({ className, children, ...props }) => {
	const [width, setWidth] = useState(window.innerWidth)

	let navigate = useNavigate()

	useEffect(() => {
		const handleResizeWindow = () => setWidth(window.innerWidth)
		window.addEventListener("resize", handleResizeWindow)
		return () => {
			window.removeEventListener("resize", handleResizeWindow)
		}
	}, [])

	if (width > breakPoint)
		return (
			<DesktopLayout>
				<div
					className={className}
					style={{
						minHeight: "calc(100vh - 120px)",
						maxHeight: "calc(100vh - 120px)",
						overflowX: "hidden",
					}}
				>
					<Col md="0" lg="3" xl="4"></Col>
					<Col className="col" xs="12" lg="6" xl="4">
						{children}
					</Col>
					<Col md="0" lg="3" xl="4"></Col>
				</div>
			</DesktopLayout>
		)

	return (
		<div className={className}>
			<Col md="0" lg="3" xl="4"></Col>
			<Col className="layout-col" xs="12" lg="6" xl="4">
				<div onClick={() => navigate(-1)} className="layout-row-close">
					<img src={Close} alt=" " style={{ height: "20px", width: "20px" }} />
				</div>
				{children}
			</Col>
			<Col md="0" lg="3" xl="4" className="right-container"></Col>
		</div>
	)
}

export default styled(AccountLayout)`
	min-height: 100vh;
	background-color: ${Colors.darkBlueGreen};
	display: flex;

	.row {
		margin: 0;
		padding: 0;
	}
	.col {
		margin: 0;
		padding: 0;
	}

	.layout-col {
		margin: 0;
		padding: 0;
		flex-direction: column;
		display: flex;
	}

	.layout-row-close {
		padding: 20px;
		display: flex;
		justify-content: flex-end;
		cursor: pointer;
	}

	.account-container {
		padding: 50px 25px 60px;
	}

	.account-title {
		color: ${Colors.white};
		font-size: 35px;
		font-weight: 700;
		line-height: 37px;
		margin-bottom: 50px;
	}

	.preview-pp {
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			height: 170px;
			width: 105px;
			object-fit: cover;
			border-radius: 52px;
		}
	}

	.input-media-pp {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	.input-media-pp + label {
		cursor: pointer;
	}

	.account-table {
		margin-top: 35px;
		width: 100%;
		th {
			font-size: 20px;
			font-weight: 100;
			line-height: 15px;
			letter-spacing: -0.16px;
			color: ${Colors.middleBlue};
			padding: 15px 0;
			// vertical-align: baseline;
		}
		td {
			font-size: 20px;
			font-weight: 100;
			line-height: 15px;
			letter-spacing: -0.16px;
			color: ${Colors.white};
			padding: 15px 0;

			input {
				color: ${Colors.darkBlueGreen};
				border: none;
				border-radius: 15px;
				padding: 10px;
				max-width: 200px;
			}
		}
	}

	.submit {
		margin-top: 25px;
		height: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 25px;
		background-color: ${Colors.lightGreen};
		cursor: pointer;
		color: ${Colors.white};
		font-size: 16px;
		font-weight: 600;
		letter-spacing: -0.12px;
	}

	.logout {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 30px;
	}
`
