export const Colors = {
	white: "#FFFFFF",
	black: "#000000",
	dark: "#1C1E20",
	darkGreen: "#0E3E28",
	middleGreen: "#9EA718",
	lightGreen: "#C4CF15",
	brightGreen: "#1CEA04",
	darkBlueGreen: "#457373",
	darkBlueGreen05: "rgba(69,115,115,0.5)",
	lightBlueGreen: "#6CA2A4",
	middleBlueGreen: "#588585",
	red: "#D80000",
	lightBlue: "#F0F9FA",
	middleBlue: "#99C5C8",
	darkGrey: "#282D2D",
	lightGrey: "#FAFAFA",
}
