import React from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { Colors } from "constants/colors"

const UserLayout = ({ className, children, title, ...props }) => {
	return (
		<div className={className}>
			<Col md="0" lg="3" xl="4"></Col>
			<Col className="layout-col">
				<Row className="layout-row-title">{title}</Row>
				<Row className="layout-row">{children}</Row>
			</Col>
			<Col md="0" lg="3" xl="4"></Col>
		</div>
	)
}

export default styled(UserLayout)`
	height: inherit;
	background-color: ${Colors.darkBlueGreen};
	display: flex;
	.layout-col {
		margin: 0px;
		padding: 50px 25px;
		height: 100vh;
		flex-direction: column;
		display: flex;
	}
	.layout-row-title {
		padding: 0;
		margin: 0;
		margin-bottom: 100px;
		font-size: 35px;
		font-weight: bold;
		color: ${Colors.white};
		line-height: 37px;
	}
	.layout-row {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
`
