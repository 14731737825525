import React from "react"
import { useNavigate } from "react-router-dom"
import Router from "router"
import { DescriptionContainer, ArticleItemContainer, RubriqueContainer } from "styles/Article.style"

const ArticleItem = ({ article, ...props }) => {
	let navigate = useNavigate()
	return (
		<ArticleItemContainer
			onClick={() => navigate(Router.explorer.article.replace(":id", article.id))}
		>
			{article && article.vignette && (
				<img
					src={article.vignette.contentUrl}
					alt=""
					style={{
						height: "122px",
						width: "122px",
						objectFit: "cover",
					}}
				/>
			)}
			<DescriptionContainer>
				{article.rubrique && (
					<RubriqueContainer>{article.rubrique.titre.toUpperCase()}</RubriqueContainer>
				)}
				{article.titre}
			</DescriptionContainer>
		</ArticleItemContainer>
	)
}

export default ArticleItem
