import React from "react"
import Jardiner from "assets/jardiner-contour.svg"
import JardinerGreen from "assets/jardiner-green.svg"

const JardinerIcon = ({ active, ...porps }) => {
	if (active) return <img src={JardinerGreen} alt="" />
	return <img src={Jardiner} alt="" />
}

export default JardinerIcon
