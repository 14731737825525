import styled from "styled-components"
import { Colors } from "constants/colors"

export const NavigationResponsiveContainer = styled.div`
	background-color: ${Colors.white};
	height: calc(100vh - 120px);
	display: flex;
	flex-direction: column;
	padding: 35px 30px 20px;
	overflow-x: hidden;
	width: 80px;
	border-right: 1px solid ${Colors.lightBlue};
	// box-shadow: 0 0 20px 0 rgba(19, 62, 85, 0.26);

	.nav-link {
		display: flex;
		color: ${Colors.darkBlueGreen};
		margin: 0 0 60px;
		align-items: center;
		img {
			margin-right: 15px;
		}
	}

	.nav-link.active {
		font-weight: 700;
	}

	.nav-item {
	}
`

export const BottomBarContainer = styled.div`
	background-color: ${Colors.white};
	height: 80px;
	position: fixed;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	padding: 0 25px;
	z-index: 3;
`
