import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import styled from "styled-components"
import Router from "router"
import { Colors } from "constants/colors"
import { ARTICLE_ENDPOINT, EVENEMENT_ENDPOINT } from "constants/endpoints"
import { breakPoint } from "constants/front"
import api from "services/api"
import HubLayout from "components/common/HubLayout"
import Loader from "components/common/Loader"
import ArticleItem from "./ArticleItem"
import EventItem from "./EventItem"
import HubDesktop from "./HubDesktop"
import { TabTitleContainer, PartagerTabTitle, JardinerTabTitle } from "styles/Hub.style"

const Partager = ({ className, ...props }) => {
	const [width, setWidth] = useState(window.innerWidth)
	const [events, setEvents] = useState([])
	const [articles, setArticles] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	let navigate = useNavigate()

	useEffect(() => {
		const handleResizeWindow = () => setWidth(window.innerWidth)
		window.addEventListener("resize", handleResizeWindow)
		return () => {
			window.removeEventListener("resize", handleResizeWindow)
		}
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			await api
				.get(EVENEMENT_ENDPOINT + "?itemsPerPage=3&page=1&order[createdAt]=desc")
				.then(data => {
					setEvents(data["hydra:member"])
				})
			await api
				.get(ARTICLE_ENDPOINT + "?itemsPerPage=3&page=1&statut=1&order[createdAt]=desc")
				.then(data => {
					setArticles(data["hydra:member"])
				})
			setIsLoading(false)
		}
		fetchData()
	}, [])

	if (width > breakPoint) return <HubDesktop />

	return (
		<HubLayout withSpray={true}>
			<Row className="layout-row">
				<Col style={{ position: "relative" }}>
					<TabTitleContainer className="active"></TabTitleContainer>
					<PartagerTabTitle className="active" onClick={() => navigate(Router.hub.partager)}>
						Partager
					</PartagerTabTitle>
				</Col>
				<Col style={{ position: "relative" }}>
					<TabTitleContainer></TabTitleContainer>
					<JardinerTabTitle onClick={() => navigate(Router.hub.jardiner)}>
						Jardiner
					</JardinerTabTitle>
				</Col>
			</Row>
			<Row className="layout-row">
				<div className={className}>
					{isLoading ? (
						<Loader
							mini={false}
							backgroundColor={Colors.darkBlueGreen}
							style={{ paddingTop: "150px" }}
						/>
					) : (
						<>
							{" "}
							<div className="partager-container">
								{events && events.map((e, key) => <EventItem event={e} key={key} />)}
								<Link to={Router.partager} className="see-more-link">
									Voir plus
								</Link>
							</div>
							<div className="explorer-container">
								<div className="explorer-title">Explorer</div>
								{articles && articles.map((a, key) => <ArticleItem article={a} key={key} />)}
								<Link to={Router.explorer.explorer} className="see-more-link">
									Voir plus
								</Link>
							</div>
						</>
					)}
				</div>
			</Row>
		</HubLayout>
	)
}

export default styled(Partager)`
	padding: 0;
	.partager-container {
		display: flex;
		flex-direction: column;
		background-color: ${Colors.darkBlueGreen};
		padding: 30px 20px 45px;
	}
	.explorer-container {
		display: flex;
		flex-direction: column;
		background-color: ${Colors.darkGreen};
		padding: 20px 20px 120px;
	}
	.explorer-title {
		color: ${Colors.white};
		font-family: Lettown Hills;
		font-size: 25px;
		line-height: 37px;
		letter-spacing: -0.2px;
		margin-bottom: 30px;
	}
	.see-more-link {
		color: ${Colors.white};
		font-size: 12px;
		line-height: 14px;
		letter-spacing: -0.09px;
		text-align: right;
		margin-top: 10px;
	}
`
