import React, { useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import Router from "router"
import { Colors } from "constants/colors"
import { ARTICLE_ENDPOINT, RUBRIQUE_ENDPOINT } from "constants/endpoints"
import { breakPoint } from "constants/front"
import api from "services/api"
import DesktopLayout from "components/common/DesktopLayout"
import Loader from "components/common/Loader"
import DesktopMenu from "components/Navigation/DesktopMenu"
import NavigationBar from "components/Navigation/NavigationBar"
import ArticleItem from "components/Hub/ArticleItem"
import {
	ArticleUneItemContainer,
	DescriptionUneContainer,
	FiltreRubrique,
	FiltreRubriqueSelected,
	ListeRubriquesContainer,
	RubriqueContainer,
} from "styles/Article.style"

const ContenuExplorer = ({ style, ...props }) => {
	const [articles, setArticles] = useState([])
	const [articleUne, setArticleUne] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [rubriques, setRubriques] = useState([])
	const [selectedRubrique, setSelectedRubrique] = useState(null)

	let navigate = useNavigate()

	useEffect(() => {
		api.get(RUBRIQUE_ENDPOINT).then(data => {
			setRubriques(data["hydra:member"])
		})
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			if (selectedRubrique && selectedRubrique.id) {
				await api
					.get(
						ARTICLE_ENDPOINT +
							"?statut=1&pagination=false&order[updatedAt]=desc&rubrique=" +
							selectedRubrique.id
					)
					.then(data => {
						setArticles(data["hydra:member"])
						let randomIndex = Math.floor(Math.random() * data["hydra:totalItems"])
						setArticleUne(data["hydra:member"][randomIndex])
					})
			} else {
				await api
					.get(ARTICLE_ENDPOINT + "?statut=1&pagination=false&order[updatedAt]=desc")
					.then(data => {
						setArticles(data["hydra:member"])
						let randomIndex = Math.floor(Math.random() * data["hydra:totalItems"])
						setArticleUne(data["hydra:member"][randomIndex])
					})
			}
			// setIsLoading(false)
		}
		fetchData()
	}, [selectedRubrique])

	return (
		<div className="explorer-container" style={style}>
			<div className="explorer-title">Explorer</div>
			<ListeRubriquesContainer>
				{!selectedRubrique ? (
					<FiltreRubriqueSelected>TOUT LE MAG</FiltreRubriqueSelected>
				) : (
					<FiltreRubrique onClick={() => setSelectedRubrique(null) & setIsLoading(true)}>
						TOUT LE MAG
					</FiltreRubrique>
				)}
				{rubriques &&
					rubriques.map((r, index) => {
						if (selectedRubrique && selectedRubrique.id === r.id) {
							return (
								<FiltreRubriqueSelected key={index}>{r.titre.toUpperCase()}</FiltreRubriqueSelected>
							)
						} else {
							return (
								<FiltreRubrique
									onClick={() => setSelectedRubrique(r) & setIsLoading(true)}
									key={index}
								>
									{r.titre.toUpperCase()}
								</FiltreRubrique>
							)
						}
					})}
			</ListeRubriquesContainer>
			{articleUne && (
				<ArticleUneItemContainer
					onClick={() => navigate(Router.explorer.article.replace(":id", articleUne.id))}
				>
					{articleUne.vignette && (
						<img
							src={articleUne.vignette.contentUrl}
							alt=""
							style={{ height: "122px", width: "100%", objectFit: "cover" }}
						/>
					)}
					<DescriptionUneContainer>
						{articleUne.rubrique && (
							<RubriqueContainer>{articleUne.rubrique.titre.toUpperCase()} </RubriqueContainer>
						)}
						{articleUne.titre}
					</DescriptionUneContainer>
				</ArticleUneItemContainer>
			)}
			{articles && articles.map((e, key) => <ArticleItem article={e} key={key} />)}
		</div>
	)
}

const Explorer = ({ className, ...props }) => {
	const [width, setWidth] = useState(window.innerWidth)

	useEffect(() => {
		const handleResizeWindow = () => setWidth(window.innerWidth)
		window.addEventListener("resize", handleResizeWindow)
		return () => {
			window.removeEventListener("resize", handleResizeWindow)
		}
	}, [])

	if (width > breakPoint)
		return (
			<DesktopLayout>
				<div
					className={className}
					style={{
						minHeight: "calc(100vh - 120px)",
						maxHeight: "calc(100vh - 120px)",
						overflowX: "hidden",
					}}
				>
					<Col md="0" lg="3" xl="4"></Col>
					<ContenuExplorer />
					<Col md="0" lg="3" xl="4"></Col>
				</div>
			</DesktopLayout>
		)

	return (
		<div className={className}>
			<Col md="0" lg="3" xl="4">
				{width > breakPoint && <DesktopMenu />}
			</Col>
			<Col className="layout-col" xs="12" lg="6" xl="4">
				<ContenuExplorer />
			</Col>
			<Col md="0" lg="3" xl="4" className="right-container"></Col>
			{width <= breakPoint && <NavigationBar withSpray={false} />}
		</div>
	)
}

export default styled(Explorer)`
	min-height: 100vh;
	background-color: ${Colors.darkGreen};
	display: flex;

	.row {
		margin: 0;
		padding: 0;
	}
	.col {
		margin: 0;
		padding: 0;
	}

	.layout-col {
		margin: 0;
		padding: 0;
		flex-direction: column;
		display: flex;
	}

	.explorer-container {
		padding: 50px 20px 90px;
	}

	.explorer-title {
		color: ${Colors.white};
		font-family: Lettown Hills;
		font-size: 40px;
		line-height: 48px;
		letter-spacing: -0.31px;
		margin-bottom: 50px;
		padding: 0 5px;
	}
`
