import React, { useEffect, useState } from "react"
import { Navigate, Routes, Route } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import { AuthenticationProvider } from "contexts/AuthenticationContext"
import Router from "router"
import NavigationBar from "components/Navigation/NavigationBar"
import Login from "components/Login"
import SplashScreen from "components/SplashScreen"
import Register from "components/Register/Register"
import CheckLoggedIn from "components/CheckLoggedIn"
import HubPartager from "components/Hub/Partager"
import HubJardiner from "components/Hub/Jardiner"
import Partager from "components/Partager/Partager"
import Explorer from "components/Explorer/Explorer"
import Article from "components/Explorer/Article"
import Galerie from "components/Galerie/Galerie"
import Account from "components/Account/Account"
import Password from "components/Account/Password"
import ResetPassword from "components/ResetPassword/ResetPassword"
import ResetPasswordChange from "components/ResetPassword/ResetPasswordChange"
import "App.css"
import ConsentIndex from "components/Consent/ConsentIndex"

function App() {
	const [currentUser, setCurrentUser] = useState(undefined)
	const [isLoading, setIsLoading] = useState(false)

	// useEffect(() => {
	// 	// Wait for 3 seconds
	// 	setTimeout(() => {
	// 		setIsLoading(false)
	// 	}, 1500)
	// }, [])

	return (
		<div>
			<AuthenticationProvider value={{ user: currentUser, setUser: setCurrentUser }}>
				{isLoading && <SplashScreen />}
				{!isLoading && (
					<>
						<CheckLoggedIn />
						<Routes>
							<Route index element={<Login />} />
							<Route path={Router.login} element={<Login />} />
							<Route path={Router.register} element={<Register />} />
							<Route path={Router.account.mdpOublie} element={<ResetPassword />} />
							<Route path={Router.account.mdpOublieChange} element={<ResetPasswordChange />} />
							<Route path={Router.account.account} element={<Account />} />
							<Route path={Router.account.mdpChange} element={<Password />} />
							<Route path={Router.consents.index} element={<ConsentIndex />} />
							<Route path={Router.hub.hub} element={<HubPartager />} />
							<Route path={Router.hub.partager} element={<HubPartager />} />
							<Route path={Router.hub.jardiner} element={<HubJardiner />} />
							<Route path={Router.partager} element={<Partager />} />
							<Route path={Router.explorer.explorer} element={<Explorer />} />
							<Route path={Router.explorer.article} element={<Article />} />
							<Route path={Router.galeriePhoto} element={<Galerie />} />
						</Routes>
					</>
				)}
			</AuthenticationProvider>
		</div>
	)
}

export default App
