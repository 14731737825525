import styled from "styled-components"

export const GalerieMediaItemContainerHub = styled.div`
	display: flex;
	height: 100px;
	max-width: 150px;
	margin-right: 10px;
`
export const GalerieContainer = styled.div`
	padding: 20px;
`
export const GalerieMediaItemContainer = styled.div`
	display: flex;
	max-width: 100%;
	margin-bottom: 20px;
	justify-content: center;
`
