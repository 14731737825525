import React from "react"
import Partager from "assets/partager-contour.svg"
import PartagerGreen from "assets/partager-green.svg"

const PartagerIcon = ({ active, ...porps }) => {
	if (active) return <img src={PartagerGreen} alt="" />
	return <img src={Partager} alt="" />
}

export default PartagerIcon
