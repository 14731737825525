import React, { useState } from "react"
import { Row, Form } from "react-bootstrap"
import UserLayout from "components/common/UserLayout"
import CGU from "../Consent/CGU"
import PolitiqueConf from "../Consent/PolitiqueConf"
import { RegisterContainer, RegisterButton, RegisterFormContainer } from "styles/Register.style"
import MentionsLegales from "components/Consent/MentionsLegales"

const RegisterStepConsent = ({ handleConsent, form, setForm, ...props }) => {
	// const [acceptNotif, setAcceptNotif] = useState(Notification.permission)

	const validateForm = () => {
		return form.acceptCGU && form.acceptPolitiqueConf && form.acceptMentionLegale
	}

	// const askNotificationPermission = () => {
	// 	// function to actually ask the permissions
	// 	function handlePermission(permission) {
	// 		setAcceptNotif(Notification.permission === "granted")
	// 	}

	// 	// Let's check if the browser supports notifications
	// 	if (!("Notification" in window)) {
	// 		console.log("This browser does not support notifications.")
	// 	} else if (checkNotificationPromise()) {
	// 		Notification.requestPermission().then(permission => {
	// 			handlePermission(permission)
	// 		})
	// 	} else {
	// 		// for Safari
	// 		Notification.requestPermission(permission => {
	// 			handlePermission(permission)
	// 		})
	// 	}
	// }

	// const checkNotificationPromise = () => {
	// 	try {
	// 		Notification.requestPermission().then()
	// 	} catch (e) {
	// 		return false
	// 	}

	// 	return true
	// }

	return (
		<UserLayout title="Consentements">
			<RegisterContainer>
				<Row
					className="h-100"
					style={{ justifyContent: "center", alignItems: "center", margin: "0" }}
				>
					<RegisterFormContainer onSubmit={handleConsent} className="login-form">
						<Form.Group
							size="md"
							controlId="acceptCGU"
							className="form-group"
							style={{ width: "100%", display: "flex" }}
						>
							<div
								className={form.acceptCGU ? "checkmark checked" : "checkmark"}
								onClick={() => setForm({ ...form, acceptCGU: !form.acceptCGU })}
							/>

							<Form.Label>
								J’accepte les
								<CGU handleAccept={() => setForm({ ...form, acceptCGU: true })}>
									<span style={{ textDecoration: "underline", cursor: "pointer" }}>
										Conditions Générales d’Utilisation
									</span>
								</CGU>
							</Form.Label>
						</Form.Group>
						<Form.Group
							size="md"
							controlId="acceptPolitiqueConf"
							className="form-group"
							style={{ width: "100%", display: "flex" }}
						>
							<div
								className={form.acceptPolitiqueConf ? "checkmark checked" : "checkmark"}
								onClick={() => setForm({ ...form, acceptPolitiqueConf: !form.acceptPolitiqueConf })}
							/>
							<Form.Label>
								J’accepte la
								<PolitiqueConf handleAccept={() => setForm({ ...form, acceptPolitiqueConf: true })}>
									<span style={{ textDecoration: "underline", cursor: "pointer" }}>
										politique de confidentialité
									</span>
								</PolitiqueConf>
							</Form.Label>
						</Form.Group>
						<Form.Group
							size="md"
							controlId="acceptMentionLegale"
							className="form-group"
							style={{ width: "100%", display: "flex" }}
						>
							<div
								className={form.acceptMentionLegale ? "checkmark checked" : "checkmark"}
								onClick={() => setForm({ ...form, acceptMentionLegale: !form.acceptMentionLegale })}
							/>
							<Form.Label>
								J’accepte les
								<MentionsLegales
									handleAccept={() => setForm({ ...form, acceptMentionLegale: true })}
								>
									<span style={{ textDecoration: "underline", cursor: "pointer" }}>
										mentions légales
									</span>
								</MentionsLegales>
							</Form.Label>
						</Form.Group>
						{/* <Form.Group
							size="md"
							controlId="acceptNotif"
							className="form-group"
							style={{ width: "100%", margin: "auto", marginTop: "10px", display: "flex" }}
						>
							<div
								// className={acceptNotif ? "checkmark checked" : "checkmark"}
								// onClick={askNotificationPermission}
								className={form.acceptNotif ? "checkmark checked" : "checkmark"}
								onClick={() => setForm({ ...form, acceptNotif: !form.acceptNotif })}
							/>
							<Form.Label>J’accepte de recevoir des notifications</Form.Label>
						</Form.Group> */}
						<div className="form-group" style={{ marginBottom: "0" }}>
							<RegisterButton type="submit" disabled={!validateForm()}>
								Valider
							</RegisterButton>
						</div>
					</RegisterFormContainer>
				</Row>
			</RegisterContainer>
		</UserLayout>
	)
}

export default RegisterStepConsent
